import { useMemo } from 'react';
import {
  Banners,
  useZustandLanguagesKitStore,
  languageSelectors,
  useTranslate,
} from 'decryption_component-kit';

import { useBalance, useAuth } from 'decryption_protocol_hooks';
import { useNavigate } from 'react-router';
import cln from 'classnames';

import HomeCategories from './components/HomeCategories';
import CasinoCategories from 'components/CasinoComponents/CasinoCategories';
import LastBigWinnersKit from 'components/ui/KitComponents/LastBigWinnersKit';
import { useZustandConnectionStore } from 'stores/connection/store';
import { isAvailableTransportSelector } from 'stores/connection/selectors';

import { useZustandWebConfiguration } from 'stores/configuration/store';
import {
  getAdditionalCategoryInfoSelector,
  getHomeBannersSelector,
} from 'stores/configuration/selectors';

import { useZustandModalStore } from 'stores/modal/store';
import { setModalInfoAction } from 'stores/modal/selectors';
import { useZustandDeviceStore } from 'stores/device/store';
import { isMobileDeviceSelector } from 'stores/device/selectors';

import { EBannerTypes } from './types';
import { ERouteNames } from 'Routes/types';
import { LotteryTypes } from 'stores/lottery/types';
import { AuthInfo } from 'decryption_protocol/dist/lib/store/user/types';
import { ECasinoCategoryIds } from 'decryption_protocol/dist/lib/store/casino/entities/categories/types';
import { ModalTypes } from 'stores/modal/types';
import { BalanceInfo } from 'decryption_protocol/dist/lib/store/user/entities/balance/types';
import { Banner } from 'decryption_component-kit/dist/lib/components/Banners/types';

import { APP_TYPE } from 'constants/ui';

import styles from './Home.module.css';
import animation from 'styles/animation.module.css';
import './sliderHome.css';

const CasinoHome = () => {
  const { getText } = useTranslate('', '');

  const navigate = useNavigate();

  const isConnectedApp = useZustandConnectionStore(
    isAvailableTransportSelector
  );

  const isMobile = useZustandDeviceStore(isMobileDeviceSelector);

  const setModal = useZustandModalStore(setModalInfoAction);

  const activeLang = useZustandLanguagesKitStore(
    languageSelectors.getActiveLanguageNameKitSelector
  );

  const banners = useZustandWebConfiguration(getHomeBannersSelector);

  const isAuthInfo = useAuth({ isConnectedApp }) as AuthInfo;

  const balanceInfo = useBalance({
    isConnectedApp,
    isBalanceInfo: true,
  }) as BalanceInfo | null;

  const additionalCategoryInfo = useZustandWebConfiguration(
    getAdditionalCategoryInfoSelector
  );

  const handleOpenTG = (id: string) => {
    const banner = banners.find((b) => b.id === id);

    if (!banner?.redirect || !('redirect' in banner)) {
      return;
    }

    const link = banner.redirect[activeLang] || banner.redirect.default;

    const a = document.createElement('a');

    a.href = link;
    a.target = '_blank';
    a.click();
  };

  const promotionsBtn = () => {
    if (!isAuthInfo?.isAuth) {
      setModal(ModalTypes.SignUp);

      return;
    }
    // const type = !isAuthInfo?.isAuth
    //   ? ModalTypes.SignUp
    //   : ModalTypes.MoneyOperations;

    // setModal(type);
    navigate(ERouteNames.BigGiveAway);
  };

  const handleBannerClick = (id: keyof typeof EBannerTypes) => () => {
    switch (id) {
      case 'Asian':
        additionalCategoryInfo &&
          navigate(
            ERouteNames.CasinoProviders.replace(
              ':providerNames',
              additionalCategoryInfo.providerCategoryNames
            )
              .replaceAll(':page', '0')
              .replaceAll(':scroll', '0')
          );
        break;
      case 'Betting':
        navigate(ERouteNames.FrameBetting);
        break;
      case 'Promotions':
        promotionsBtn();
        break;
      case 'TelegramBot':
        return handleOpenTG(id);
      case 'Originals':
        navigate(
          ERouteNames.CasinoCategories.replaceAll(
            ':categoryId',
            `${ECasinoCategoryIds.Turbo}`
          )
            .replace(':subCategoryId', 'all')
            .replaceAll(':page', '1')
            .replaceAll(':scroll', '0')
        );

        break;
      case 'Lottery':
        navigate(ERouteNames.Lottery.replace(':type', LotteryTypes[0]));
        break;
      case 'Referral':
        navigate(ERouteNames.ReferralProgram);
        break;
      case 'Welcome':
        setModal(isAuthInfo ? ModalTypes.Bonuses : ModalTypes.SignIn);
        break;

      default:
        break;
    }
  };

  const adaptedBanners = useMemo(() => {
    const { successDepositCnt = 0 } = balanceInfo || {};

    const adaptedBanners: Banner[] = [];

    banners.forEach((b) => {
      if ('depositDependency' in b) {
        const { depositDependency } = b as Banner & {
          depositDependency: number;
        };

        if (depositDependency <= successDepositCnt) {
          return true;
        }
      }
      adaptedBanners.push({
        ...b,
        subTitle: isMobile ? undefined : b.subTitle,
      });
    });

    return adaptedBanners;
  }, [getText, balanceInfo, isMobile]);

  return (
    <>
      <div className={cln(styles.wrapperCasinoHome, animation.animationFadeIn)}>
        <CasinoCategories type="buttons" withoutSubCategory withoutSearch />

        <div className={cln(styles.wrapperSlider, styles.width)}>
          <Banners
            appType={APP_TYPE}
            isAuth={Boolean(isAuthInfo)}
            isMobile={isMobile}
            banners={adaptedBanners}
            handleClick={handleBannerClick}
            language={activeLang}
          />
        </div>

        <HomeCategories />
      </div>

      <LastBigWinnersKit classes={styles.marginB} />
    </>
  );
};

export default CasinoHome;
