import { ERequestIds } from 'decryption_protocol/dist/lib/binary/types';
import { PaymentChannelType } from 'decryption_protocol/dist/lib/store/user/types';
import { useZustandFormsStore } from 'stores/money/forms/store';
import { DepositFormInfo, InrUpiFastProps } from 'stores/money/forms/types';

// DEPOSITS
type ReturnPrepareData = {
  data: DepositFormInfo & {
    bonusId: number;
  };
  key: string;
} | null;

export const prepareDataForBDTDeposit = (
  channelId: PaymentChannelType
): ReturnPrepareData => {
  const { bonusIdForActivate, formInfo } = useZustandFormsStore.getState();

  const isPaykasma = [
    PaymentChannelType.BKashFast,
    PaymentChannelType.ApayBkash,
    PaymentChannelType.NagadApi,
    PaymentChannelType.ApayNagad,
    PaymentChannelType.EasypaisaFast,
    PaymentChannelType.Easypaisa,
    PaymentChannelType.JazzCash,
  ].includes(channelId);

  const key = isPaykasma ? '32x' : '14x';

  if (!formInfo || !formInfo.isDeposit) {
    return null;
  }

  return {
    key,
    data: {
      ...formInfo.form,
      amount: formInfo.form.amount,
      bonusId: bonusIdForActivate || 0,
    },
  };
};

type ReturnPreparePixData = {
  key: string;
  data: {
    bonusId: number;
    amount: number;
    currencyId: number;
    channelType: number;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    city: string;
    zip: string;
    pinCode: string;
    state: string;
    address: string;
    requestId: ERequestIds;
  };
} | null;

export const prepareDataForPix = (
  channelType: PaymentChannelType,
  key: string
): ReturnPreparePixData => {
  const { bonusIdForActivate, formInfo } = useZustandFormsStore.getState();

  if (!formInfo || !formInfo.isDeposit) {
    return null;
  }

  const { requestId, currencyId, amount } = formInfo.form;

  if (!formInfo || requestId === ERequestIds.GetBillingAddress) {
    return null;
  }

  return {
    key,
    data: {
      bonusId: bonusIdForActivate || 0,
      currencyId: currencyId,
      channelType,
      amount: Math.floor(+amount * 100),
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      city: '',
      zip: '',
      pinCode: '',
      state: '',
      address: '',
      requestId: requestId,
    },
  };
};

export const prepareDataForGettingBillingAddress = () => {
  const { formInfo } = useZustandFormsStore.getState();

  if (
    !formInfo ||
    !formInfo.isDeposit ||
    formInfo.form.requestId !== ERequestIds.GetBillingAddress
  ) {
    return null;
  }

  return formInfo.form;
};

// MPAY DEP
export const prepareDataForCripto = () => {
  const { formInfo, bonusIdForActivate } = useZustandFormsStore.getState();

  if (!formInfo || !formInfo.isDeposit) {
    return null;
  }

  const { amount, channelId } = formInfo.form;

  return {
    key: '37x',
    data: {
      ...formInfo.form,
      amount: Math.floor(+amount * 100),
      channelType: channelId,
      bonusId: bonusIdForActivate || 0,
    },
  };
};
export const prepareDataForMPay = () => {
  const { formInfo, bonusIdForActivate } = useZustandFormsStore.getState();

  if (!formInfo || !formInfo.isDeposit) {
    return null;
  }

  const { amount, channelId } = formInfo.form;

  return {
    key: '60x',
    data: {
      name: '',
      familyName: '',
      birthday: '',
      tcnumber: '',
      ...formInfo.form,
      amount: Math.floor(+amount * 100),
      channelType: channelId,
      bonusId: bonusIdForActivate || 0,
    },
  };
};
export const prepareDataForMonetics = () => {
  const { formInfo, bonusIdForActivate } = useZustandFormsStore.getState();

  if (!formInfo || !formInfo.isDeposit) {
    return null;
  }

  const { amount, channelId } = formInfo.form;

  return {
    key: '61x',
    data: {
      name: '',
      familyName: '',
      email: '',
      ...formInfo.form,
      amount: Math.floor(+amount * 100),
      channelType: channelId,
      bonusId: bonusIdForActivate || 0,
    },
  };
};
// TURKISH

export const prepareTurkishData = (key: string) => {
  const { formInfo, bonusIdForActivate } = useZustandFormsStore.getState();

  if (!formInfo || !formInfo.isDeposit) {
    return null;
  }

  const { amount = '' } = formInfo.form;

  return {
    key,
    data: {
      tcNumber: '',
      birthday: '',
      email: '',

      ...formInfo.form,
      bonusId: bonusIdForActivate || 0,
      amount: amount ? +amount * 100 : 0,
    },
  };
};
// Wellex pay
export const prepareWellexPayData = () => {
  const { formInfo, bonusIdForActivate } = useZustandFormsStore.getState();

  if (!formInfo || !formInfo.isDeposit) {
    return null;
  }

  const { amount = '' } = formInfo.form;

  return {
    key: '65x',
    data: {
      ...formInfo.form,
      bonusId: bonusIdForActivate || 0,
      amount: amount ? +amount * 100 : 0,
    },
  };
};

export const prepareRubleData = () => {
  const { formInfo, bonusIdForActivate } = useZustandFormsStore.getState();

  if (!formInfo || !formInfo.isDeposit) {
    return null;
  }

  const { amount = '' } = formInfo.form;

  return {
    key: '67x',
    data: {
      ...formInfo.form,
      bonusId: bonusIdForActivate || 0,
      amount: amount ? +amount * 100 : 0,
      cardNumber: '',
      year: '',
      month: '',
      cvv: '',
      sanderName: '',
      email: '',
    },
  };
};
export const prepareVivaraData = () => {
  const { formInfo, bonusIdForActivate } = useZustandFormsStore.getState();

  if (!formInfo || !formInfo.isDeposit) {
    return null;
  }

  const { amount = '' } = formInfo.form;

  return {
    key: '69x',
    data: {
      ...formInfo.form,
      bonusId: bonusIdForActivate || 0,
      amount: amount ? +amount * 100 : 0,
    },
  };
};

export const prepareInrData = () => {
  const { formInfo, bonusIdForActivate } = useZustandFormsStore.getState();

  if (!formInfo || !formInfo.isDeposit) {
    return null;
  }

  const { amount = '' } = formInfo.form;

  return {
    key: '32x',
    data: {
      transaction: '',
      ...formInfo.form,
      bonusId: bonusIdForActivate || 0,
      amount,
    },
  };
};

export const prepareInrOctoPayData = () => {
  const { formInfo, bonusIdForActivate } = useZustandFormsStore.getState();

  if (!formInfo || !formInfo.isDeposit) {
    return null;
  }

  const { amount = '' } = formInfo.form as InrUpiFastProps;

  return {
    key: '71x',
    data: {
      ...formInfo.form,
      bonusId: bonusIdForActivate || 0,
      amount: Math.ceil(+amount * 100),
    },
  };
};

export const preparePrkMoneticsData = () => {
  const { formInfo, bonusIdForActivate } = useZustandFormsStore.getState();

  if (!formInfo || !formInfo.isDeposit) {
    return null;
  }

  const { amount = '' } = formInfo.form;

  return {
    key: '73x',
    data: {
      ...formInfo.form,
      bonusId: bonusIdForActivate || 0,
      amount: Math.ceil(+amount * 100),
    },
  };
};
export const prepareDataForGLDNBKash = () => {
  const { formInfo, bonusIdForActivate } = useZustandFormsStore.getState();

  if (!formInfo || !formInfo.isDeposit) {
    return null;
  }

  const { amount = '' } = formInfo.form;

  return {
    key: '75x',
    data: {
      ...formInfo.form,
      bonusId: bonusIdForActivate || 0,
      amount: Math.ceil(+amount * 100),
    },
  };
};
