import { useEffect, useState } from 'react';
import {
  useGetActiveTournamentPlayers,
  useGetActiveTournamentInfo,
} from 'decryption_protocol_hooks';

import TournamentCardComponentKit from 'components/ui/KitComponents/TournamentCard';
import CasinoTournamentPlayer from 'components/ui/TournamentPlayer';
import ComponentKitBaseButton from 'components/ui/KitComponents/Buttons/BaseButton';
import CasinoSignInTournamentPlayer from '../CasinoSignInTournamentPlayer';
import SkeletLoader from 'components/ui/Loaders/SkeletLoader';
import HOCRequestLoader from 'components/HOCs/HOCRequestLoader';

import {
  AdaptedCasinoTournament,
  TournamentPlayer,
} from 'decryption_protocol/dist/lib/store/casino/entities/casinoTournaments/types';
import { ERequestIds } from 'decryption_protocol/dist/lib/binary/types';
import { ModalTypes } from 'stores/modal/types';

import { useZustandModalStore } from 'stores/modal/store';
import { closeAndOpenModalAction } from 'stores/modal/selectors';
import { isAvailableTransportSelector } from 'stores/connection/selectors';

import { DEFAULT_TOP_PLAYERS, DEFAULT_TOURNAMENT } from './constants';

import { useZustandConnectionStore } from 'stores/connection/store';

import styles from './CasinoTournament.module.css';

const CasinoTournament = () => {
  const [topPlayers, setPlayers] =
    useState<TournamentPlayer[]>(DEFAULT_TOP_PLAYERS);

  const setModalInfo = useZustandModalStore(closeAndOpenModalAction);

  const isConnectedApp = useZustandConnectionStore(
    isAvailableTransportSelector
  );

  const tournamentInfo = useGetActiveTournamentInfo({
    isConnectedApp,
  }) as null | AdaptedCasinoTournament;

  const players = useGetActiveTournamentPlayers({
    isConnectedApp,
  }) as TournamentPlayer[];

  const toggleShowMoreState = () => {
    setModalInfo(ModalTypes.CasinoTournamentPlayers);
  };

  useEffect(() => {
    if (players.length) {
      setPlayers(players.slice(0, 3));
    }
  }, [players]);

  return (
    <HOCRequestLoader
      requestId={ERequestIds.CasinoTournaments}
      command="\\36x"
      customLoader={<SkeletLoader classes={styles.skeletWrapper} />}
    >
      <div className={styles.wrapperCasinoTournament}>
        <div className={styles.wrapperTournamentCard}>
          {/* TODO make a hoc  */}
          {tournamentInfo ? (
            <TournamentCardComponentKit {...tournamentInfo} key={1} />
          ) : (
            <TournamentCardComponentKit
              {...DEFAULT_TOURNAMENT}
              isFinished
              key={2}
            />
          )}
        </div>

        <div className={styles.wrapperPlayer}>
          {topPlayers.map((player, inx) => (
            <CasinoTournamentPlayer
              classes={styles.wrapperPlayer}
              key={player.id}
              position={inx + 1}
              {...player}
            />
          ))}
        </div>

        <div className={styles.dash} />

        <div className={styles.wrapperPlayer}>
          {tournamentInfo && (
            <CasinoSignInTournamentPlayer tournamentId={tournamentInfo.id} />
          )}
        </div>

        {tournamentInfo && (
          <ComponentKitBaseButton
            color="dark"
            text="showMoreBtn"
            handleClick={toggleShowMoreState}
            size="--button-full"
            buttonHeight="--button-height-l"
            centreText
          />
        )}
      </div>
    </HOCRequestLoader>
  );
};

export default CasinoTournament;
