import {
  ModalLayout,
  useTranslate,
  ShareSvg,
  CopySvg,
} from 'decryption_component-kit';

import { useReferralLink } from 'decryption_protocol_hooks';
import ComponentKitBaseButton from 'components/ui/KitComponents/Buttons/BaseButton';
import ComponentKitBaseInputUi from 'components/ui/KitComponents/Inputs/ComponentKitInputUi';
import ReferralSubTitle from '../ReferralSubTitle';

import { REFERRAL_KEY } from 'constants/ui';
import { useZustandConnectionStore } from 'stores/connection/store';
import { isAvailableTransportSelector } from 'stores/connection/selectors';

import { copyToClipboard } from 'utils/shared';
import { useZustandModalStore } from 'stores/modal/store';
import { setModalInfoAction } from 'stores/modal/selectors';
import { ModalTypes } from 'stores/modal/types';

import styles from './ReferralCopyContent.module.css';

const ReferralCopyContent = () => {
  const isConnectedApp = useZustandConnectionStore(
    isAvailableTransportSelector
  );

  const onClose = useZustandModalStore(setModalInfoAction);

  const { name, getText } = useTranslate('referral.shareTitle', '');

  const referralLink = useReferralLink({
    baseUrl: `${window.origin}?${REFERRAL_KEY}=`,
    isConnectedApp,
  });

  const handleShareInfo = () => {
    if (!navigator.share) {
      copyToClipboard(referralLink)();

      return;
    }

    navigator.share({ title: name, url: referralLink });
  };

  const onCloseModal = () => {
    onClose(ModalTypes.Unknown);
  };

  return (
    <ModalLayout
      classes={styles.wrapperReferralCopyContent}
      popUpHeaderProps={{
        title: getText('referral.shareModal.title'),
      }}
      buttonClose={onCloseModal}
    >
      <>
        <ReferralSubTitle subTitle="referral.shareModal.subTitle" />

        {referralLink && (
          <ComponentKitBaseInputUi
            placeholder=" "
            disabled
            handleChange={console.info}
            value={referralLink}
            size="full"
            classes={styles.marginB}
          >
            <CopySvg handleClick={copyToClipboard(referralLink)} />
          </ComponentKitBaseInputUi>
        )}

        <ComponentKitBaseButton
          text="referral.share"
          size="--button-full"
          buttonHeight="--button-height-xxl"
          handleClick={handleShareInfo}
          centreText
          color="primary"
          startIcon={<ShareSvg classes={styles.bg} />}
          classesText={styles.btnColorText}
        />
      </>
    </ModalLayout>
  );
};

export default ReferralCopyContent;
