import {
  useGetActiveBonuses,
  useGetAvailableBonuses,
} from 'decryption_protocol_hooks';
import { AvailableBonusesResponse } from 'decryption_protocol/dist/lib/store/operations/entities/availableBonuses/types';

import FreeSpin from 'components/ui/Bonuses/FreeSpin';
import BonusOnDeposit from 'components/ui/Bonuses/BonusOnDeposit';
import BonusPack from 'components/BonusCabinet/BonusPack';
import { Text } from 'decryption_component-kit';

import { isAvailableTransportSelector } from 'stores/connection/selectors';
import { useZustandConnectionStore } from 'stores/connection/store';
import { useZustandBonusPackStore } from 'stores/user/bonusPack/store';
import { isAvailableBonusPackSelector } from 'stores/user/bonusPack/selectors';

import styles from './GeneralBonuses.module.css';

const GeneralBonuses = () => {
  const isConnectedApp = useZustandConnectionStore(
    isAvailableTransportSelector
  );

  const isAvailableBonusPack = useZustandBonusPackStore(
    isAvailableBonusPackSelector
  );

  const { bonusOnDeposit: bonusOnDeposits = [], freeSpins = [] } =
    useGetActiveBonuses({
      isConnectedApp,
    }) as any; //ActiveBonusesResponse;

  const {
    bonusOnDeposit: availableBonusOnDeposit = [],
    freeSpins: availableFreeSpins = [],
  } = useGetAvailableBonuses({
    isConnectedApp,
  }) as AvailableBonusesResponse;

  console.info(bonusOnDeposits, 'bonusOnDeposits');

  const isActiveBonuses = bonusOnDeposits.length || freeSpins.length;

  const isAvailableBonuses =
    availableFreeSpins.length || availableBonusOnDeposit.length;

  return (
    <div>
      {!!isActiveBonuses && (
        <>
          <div className={styles.activeBonuses}>
            {bonusOnDeposits.map((data: any) => {
              const { rolled = 0, fullRolled = 0, rollingTime = 0 } = data;

              const percent = !rolled ? 0 : (rolled * 100) / fullRolled;

              return (
                <BonusOnDeposit
                  key={data.id}
                  isActive
                  minBet={data.minBet}
                  maxBet={data.maxBet}
                  maxBonus={data.maxBonus}
                  operationId={data.id}
                  wager={data.wager}
                  timeStamp={data.endTimestamp}
                  promotion={data.bonusPromoValue}
                  // time for rolling
                  rollingTime={rollingTime}
                  fullRolled={fullRolled}
                  rolled={rolled}
                  percent={percent}
                />
              );
            })}

            {freeSpins.map((data: any) => {
              const {
                rolled = 0,
                fullRolled = 0,
                status,
                campaign,
                rollingTime,
              } = data;
              const percent = !rolled ? 0 : (rolled * 100) / fullRolled;

              const { validFrom = 0, validUntil = 0 } = campaign || {};

              return (
                <FreeSpin
                  isActive
                  key={data.id}
                  operationId={data.id}
                  amount={data.amount}
                  wager={data.wager}
                  spins={data.campaign?.quantity || 0}
                  gameId={data.campaign?.gameId || 0}
                  minBet={data.minBet}
                  maxBet={data.maxBet}
                  maxBonus={data.maxBonus}
                  validFrom={validFrom}
                  validUntil={validUntil}
                  rollingTime={rollingTime}
                  rolled={rolled}
                  fullRolled={fullRolled}
                  percent={percent}
                />
              );
            })}
          </div>
        </>
      )}

      {!!isAvailableBonuses && (
        <>
          {/* <div className={styles.title}>
            <Text idT="newBonuses.available" />
          </div> */}
          <div className={styles.availableWrapper}>
            {availableBonusOnDeposit.map((data: any) => (
              <BonusOnDeposit
                maxBet={data.maxBet}
                maxBonus={data.maxBonus}
                minBet={data.minBet}
                key={data.id}
                operationId={data.id}
                wager={data.wager}
                timeStamp={data.endActivationTime}
                promotion={data.bonusPromoValue}
              />
            ))}

            {availableFreeSpins.map((data: any) => (
              <FreeSpin
                key={data.id}
                operationId={data.id}
                amount={data.amount}
                wager={data.wager}
                spins={data.campaign?.quantity || 0}
                gameId={data.campaign?.gameId || 0}
                minBet={data.minBet}
                maxBet={data.maxBet}
                maxBonus={data.maxBonus}
                endActivationTime={data.endActivationTime}
              />
            ))}
          </div>
        </>
      )}

      {isAvailableBonusPack && <BonusPack />}
    </div>
  );
};

export default GeneralBonuses;
