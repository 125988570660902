import {
  useGetWeeklyBonus,
  useGetActiveBonuses,
  useGetRakeBack,
} from 'decryption_protocol_hooks';
import {
  ReturnTypeRakeBake,
  ReturnTypeWeeklyBonus,
} from 'decryption_protocol/dist/lib/store/operations/entities/vipClub/types';
import {
  DrawerButton,
  useTranslate,
  BonusGiftSvg,
} from 'decryption_component-kit';

import { useZustandModalStore } from 'stores/modal/store';
import { closeAndOpenModalAction } from 'stores/modal/selectors';
import { ModalTypes } from 'stores/modal/types';
import { useZustandUserStore } from 'stores/user/personal/store';
import { isAuthorizedUserSelector } from 'stores/user/personal/selectors';
import { useZustandConnectionStore } from 'stores/connection/store';
import { isAvailableTransportSelector } from 'stores/connection/selectors';
import { useZustandCasinoDrawerStore } from 'stores/drawers/casino/store';
import { isFullCasinoDrawerSelector } from 'stores/drawers/casino/selectors';

import styles from './BonusButtons.module.css';

const BonusButtons = () => {
  const closeDrawer = useZustandModalStore(closeAndOpenModalAction);

  const isAuth = useZustandUserStore(isAuthorizedUserSelector);

  const isConnectedApp = useZustandConnectionStore(
    isAvailableTransportSelector
  );

  const isFullView = useZustandCasinoDrawerStore(isFullCasinoDrawerSelector);

  const { getText } = useTranslate('', '');

  const { bonusOnDeposit = [], freeSpins = [] } = useGetActiveBonuses({
    isConnectedApp,
  }) as any;

  const weekly = useGetWeeklyBonus({
    isAuth,
    isConnectedApp,
  }) as null | ReturnTypeWeeklyBonus;

  const rakebackInfo = useGetRakeBack({
    isConnectedApp,
  }) as null | ReturnTypeRakeBake;

  const openModal = (t: ModalTypes) => () => {
    closeDrawer(t);
  };

  const count =
    Number(Boolean(rakebackInfo && rakebackInfo.rakeback)) +
    Number(Boolean(weekly && weekly.amount)) +
    bonusOnDeposit.length +
    freeSpins.length;

  return (
    <DrawerButton
      size="full"
      text={getText('drawers.casino.bonuses')}
      handleClick={openModal(ModalTypes.Bonuses)}
      startIcon={<BonusGiftSvg classes={styles.giftSvg} />}
      classes={styles.marginBottom}
      isFullView={isFullView}
    >
      {count ? <div className={styles.red}>{count}</div> : undefined}
    </DrawerButton>
  );
};

export default BonusButtons;
