import { useGetWeeklyBonus } from 'decryption_protocol_hooks';
import { ReturnTypeWeeklyBonus } from 'decryption_protocol/dist/lib/store/operations/entities/vipClub/types';

import BonusCardComponentKit from 'components/ui/KitComponents/BonusCardComponentKit';

import { isAuthorizedUserSelector } from 'stores/user/personal/selectors';
import { useZustandUserStore } from 'stores/user/personal/store';
import { useZustandConnectionStore } from 'stores/connection/store';
import { isAvailableTransportSelector } from 'stores/connection/selectors';
import { useZustandWebConfiguration } from 'stores/configuration/store';
import { getBonusCardsSelector } from 'stores/configuration/selectors';

import { getNextSaturday } from 'utils/date';

const WeeklyCard = () => {
  const isAuth = useZustandUserStore(isAuthorizedUserSelector);

  const isConnectedApp = useZustandConnectionStore(
    isAvailableTransportSelector
  );

  const bonusImagePaths = useZustandWebConfiguration(getBonusCardsSelector);

  const weekly = useGetWeeklyBonus({
    isAuth,
    isConnectedApp,
  }) as null | ReturnTypeWeeklyBonus;

  return (
    <BonusCardComponentKit
      title="newBonuses.cards.weekly"
      description="newBonuses.cards.weeklyDescription"
      id={weekly?.id || -1}
      value={`${weekly?.amount || ''}`}
      endTimestamp={weekly?.endData || getNextSaturday()}
      bonusImagePath={bonusImagePaths?.weekly || ''}
    />
  );
};

export default WeeklyCard;
