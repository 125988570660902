import { LastBigWinners } from 'decryption_component-kit';
import { useNavigate } from 'react-router';
import { LastBigWinnerInfo } from 'decryption_component-kit/dist/lib/components/LastBigWinners/components/LastBigWinner/types';
import { ReturnSubscribeCasinoLastWinnersProps } from 'decryption_protocol/dist/lib/store/casino/entities/lastWinners/types';
import { useLastWinner } from 'decryption_protocol_hooks';
import { useEffect, useState } from 'react';

import RowHeader from 'components/CasinoComponents/CasinoCategoryRow/components/RowHeader';

import { isAvailableTransportSelector } from 'stores/connection/selectors';
import { useZustandConnectionStore } from 'stores/connection/store';

import { ECasinoCategoryIds } from 'decryption_protocol/dist/lib/store/casino/entities/categories/types';

import { ERouteNames } from 'Routes/types';

import { useZustandDeviceStore } from 'stores/device/store';
import { isMobileDeviceSelector } from 'stores/device/selectors';

import styles from './LastBigWinnersKit.module.css';

const LastBigWinnersKit = ({
  classes,
  classesCard,
}: {
  classes?: string;
  classesCard?: string;
}) => {
  const isConnectedApp = useZustandConnectionStore(
    isAvailableTransportSelector
  );

  const [initialWinners, setInitialWinners] = useState<number>(0);

  const isMobile = useZustandDeviceStore(isMobileDeviceSelector);

  const navigate = useNavigate();

  const lastWinners = useLastWinner({
    isConnectedApp,
    isMobile,
    limit: 10,
    type: 'base',
  }) as ReturnSubscribeCasinoLastWinnersProps[];

  const handleGetNewWinners = (): LastBigWinnerInfo[] => {
    const games: LastBigWinnerInfo[] = [];

    lastWinners.map((i) => {
      if (!i?.gameInfo?.imageUrl) {
        return;
      }
      const data = {
        gameImg: `${i?.gameInfo?.imageUrl}`,
        gameName: i?.gameInfo?.name,
        gameId: i?.gameInfo?.id,
        prize: `${i?.prize} $`,
        id: i?.clientId,
      };

      games.push(data);
    });

    return games;
  };

  const handleCardClick = (gameId: number) => () => {
    navigate(ERouteNames.CasinoGame.replace(':gameId', `${gameId}`));
  };

  useEffect(() => {
    if (!lastWinners.length || initialWinners) {
      return;
    }

    setInitialWinners(lastWinners.length);
  }, [initialWinners, lastWinners]);

  return (
    <div>
      <RowHeader
        iconId={ECasinoCategoryIds.Wager}
        categoryName="lastWin.title"
        classes={styles.rowHeader}
      />

      <LastBigWinners
        initialCards={initialWinners}
        handleClick={handleCardClick}
        time={3000}
        classes={classes}
        classesCard={classesCard}
        getCardInfo={handleGetNewWinners}
      />
    </div>
  );
};

export default LastBigWinnersKit;
