import { useTransportControllers } from 'decryption_protocol_hooks';
import { ModalLayout, Text, useTranslate } from 'decryption_component-kit';
import { ERequestIds } from 'decryption_protocol/dist/lib/binary/types';

import ComponentKitBaseButton from 'components/ui/KitComponents/Buttons/BaseButton';
import CircularLoader from 'components/ui/Loaders/Circular';
import HOCRequestLoader from 'components/HOCs/HOCRequestLoader';

import { useZustandModalStore } from 'stores/modal/store';
import { getModalAdditionalInfoSelector } from 'stores/modal/selectors';
import { getAuthorizationData } from 'utils/transport/main';
import { copyToClipboard } from 'utils/shared';

import styles from './Credentials.module.css';

const Credentials = () => {
  const data = useZustandModalStore(getModalAdditionalInfoSelector) || {};

  const { getText } = useTranslate('', '');

  const { sendCommand } = useTransportControllers();

  const { login = '', password = '' } = data;

  const handleSignIn = () => {
    sendCommand(
      'x02',
      getAuthorizationData(login, password, ERequestIds.AuthorizeByEmail),
      true
    );
  };

  return (
    <ModalLayout
      classes={styles.wrapperReferralFailure}
      popUpHeaderProps={{
        title: getText('popUp.credentials.title'),
      }}
      buttonClose={handleSignIn}
    >
      <div className={styles.wrapperCredentials}>
        <div className={styles.infoRow}>
          <div>
            <div className={styles.username}>
              <Text idT="popUp.credentials.username" />
            </div>
            <div className={styles.result}>{login}</div>
          </div>

          <ComponentKitBaseButton
            color="secondary"
            size="--button-xl"
            buttonHeight="--button-height-xs"
            text="copy"
            handleClick={copyToClipboard(
              `login: ${login}\npassword: ${password}`
            )}
            centreText
          />
        </div>

        <div className={styles.infoRow}>
          <div>
            <div className={styles.username}>
              <Text idT="popUp.credentials.password" />
            </div>
            <div className={styles.result}>{password}</div>
          </div>

          <ComponentKitBaseButton
            color="secondary"
            size="--button-xl"
            buttonHeight="--button-height-xs"
            text="copy"
            handleClick={copyToClipboard(
              `login: ${login}\npassword: ${password}`
            )}
            centreText
          />
        </div>

        <HOCRequestLoader
          command="\\x02"
          requestId={ERequestIds.AuthorizeByEmail}
          customLoader={<CircularLoader />}
        >
          <ComponentKitBaseButton
            color="primary"
            handleClick={handleSignIn}
            size="--button-full"
            buttonHeight="--button-height-l"
            text="popUp.credentials.submitBtn"
            centreText
          />
        </HOCRequestLoader>
      </div>
    </ModalLayout>
  );
};

export default Credentials;
