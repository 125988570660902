import cln from 'classnames';
import { DailyInfoType } from 'decryption_protocol/dist/lib/store/user/entities/daily/types';

import styles from './DailyTask.module.css';
import ComponentKitBaseButton from 'components/ui/KitComponents/Buttons/BaseButton';
import { useZustandConnectionStore } from 'stores/connection/store';
import { isAvailableTransportSelector } from 'stores/connection/selectors';
import { useDailyTaskControllers } from 'decryption_protocol_hooks';

type Props = DailyInfoType & { classes?: string; isActive: boolean };

const DailyTask = (props: Props) => {
  const { type, classes, name, id, isActive } = props;

  const isConnectedApp = useZustandConnectionStore(
    isAvailableTransportSelector
  );

  const { setActiveDailyTask } = useDailyTaskControllers(isConnectedApp);

  const handleSet = () => {
    setActiveDailyTask(id);
  };

  return (
    <div className={cln(styles.wrapperDailyTask, classes)}>
      <div className={styles.name}>
        <span>{type}</span>: {name}
      </div>

      <ComponentKitBaseButton
        text={isActive ? 'Start' : 'In progress'}
        handleClick={handleSet}
        isResized
        color="primary"
        size="--button-xs"
      />
    </div>
  );
};

export default DailyTask;
