import { useCallback, useEffect, useRef, useState } from 'react';
import {
  useBonusCoins,
  useLootBoxControllers,
} from 'decryption_protocol_hooks';
import {
  ModalLayout,
  useZustandLanguagesKitStore,
  languageSelectors,
  useTranslate,
} from 'decryption_component-kit';

import { ELootBoxCommand } from './types';

import { useZustandConnectionStore } from 'stores/connection/store';
import { isAvailableTransportSelector } from 'stores/connection/selectors';
import { addNotificationAction } from 'stores/notification/selectors';
import { useZustandNotificationStore } from 'stores/notification/store';
import { ENotificationType } from 'stores/notification/types';
import { useZustandModalStore } from 'stores/modal/store';
import { setModalInfoAction } from 'stores/modal/selectors';
import { ModalTypes } from 'stores/modal/types';

import styles from './LootBoxGame.module.css';

const LootBoxGame = () => {
  const lootBoxGame = useRef<null | HTMLIFrameElement>(null);

  const isConnectedApp = useZustandConnectionStore(
    isAvailableTransportSelector
  );

  const bonusCoin = useBonusCoins({ isConnectedApp });

  const [isReady, setReadyStatus] = useState(false);

  const lang = useZustandLanguagesKitStore(
    languageSelectors.getActiveLanguageNameKitSelector
  );

  const { name: title } = useTranslate('kgames.boxes.lootBox.title', '');

  const closeModal = useZustandModalStore(setModalInfoAction);

  const { getLootBoxGameSettings, getLootBoxResult } = useLootBoxControllers({
    isConnectedApp,
  });

  const addNotification = useZustandNotificationStore(addNotificationAction);

  const onCloseModal = () => {
    closeModal(ModalTypes.Unknown);
  };

  const sendMessageToFrame = (data: any) => {
    const frame = lootBoxGame.current;

    if (!frame || !frame?.contentWindow) {
      console.warn('lootbox ~ frame message could not be sended');

      return;
    }

    frame?.contentWindow.postMessage(data, '*');
  };

  const handleGameIsLoaded = () => {
    // Is Auth status
    sendMessageToFrame({
      type: ELootBoxCommand.VerificationStatus,
      payload: { status: true },
    });

    setReadyStatus(true);
    // Settings
    sendMessageToFrame({
      type: ELootBoxCommand.SetGameSettings,
      payload: {
        settings: getLootBoxGameSettings(),
        currencyName: 'Bonus',
      },
    });
  };

  const handleGetResult = (payload: any) => {
    const baseData = getLootBoxResult(payload.stake);

    if (baseData.isError) {
      sendMessageToFrame({
        type: ELootBoxCommand.GameResult,
        payload: {
          ...baseData,
          isError: true,
        },
      });
      addNotification({
        type: ENotificationType.Error,
        uid: 'loot-box',
        text: 'errors.balanceIsLess',
      });
    }

    return sendMessageToFrame({
      type: ELootBoxCommand.GameResult,
      payload: baseData,
    });
  };

  const frameMessage = useCallback((e: MessageEvent) => {
    try {
      if (!e.data) {
        return;
      }
      const { type, payload } = e.data as any;

      switch (type) {
        case ELootBoxCommand.GameIsLoaded:
          return handleGameIsLoaded();

        case ELootBoxCommand.GetResult:
          // send result via lib service
          return handleGetResult(payload);

        default:
          console.warn('loot box ~ MESSAGE without handlers ');
          break;
      }
    } catch (error) {
      console.error('lootbox ~ error:', error);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('message', frameMessage, true);

    return () => {
      window.removeEventListener('message', frameMessage, true);
    };
  }, [frameMessage]);

  useEffect(() => {
    if (bonusCoin === null || !isReady) {
      return;
    }
    console.info('SEND ', bonusCoin);
    sendMessageToFrame({
      type: ELootBoxCommand.BonusBalance,
      payload: { bonusCoin },
    });
  }, [bonusCoin, isReady]);

  return (
    <ModalLayout
      classes={styles.wrapperLootBoxGame}
      popUpHeaderProps={{
        title,
        classes: styles.disableMarginB,
      }}
      buttonClose={onCloseModal}
    >
      <iframe
        src={
          process.env.NODE_ENV === 'production'
            ? `https://lotbox.jocker.website?lang=${lang}`
            : `http://localhost:2998?lang=${lang}`
        }
        className={styles.frame}
        ref={lootBoxGame}
      />
    </ModalLayout>
  );
};

export default LootBoxGame;
