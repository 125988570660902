import { PaymentChannelType } from 'decryption_protocol/dist/lib/store/user/types';
import {
  getWithdrawDefaultInfo,
  getWithdrawCriptoInfo,
  getBankPixInfo,
  getOctopayInfo,
  getPixPagsmileInfo,
  getCoinPayInfo,
  getTurkishWithdrawInfo,
  getMoneticsWithdrawInfo,
  getMPayWithdrawInfo,
  getWellexPayWithdrawInfo,
  getRublesData,
  getVivaroData,
  withdrawInr,
  withdrawInrOctoPay,
} from 'utils/transport/withdrawAdapter';

const getTurkishKey = (key: PaymentChannelType) => {
  switch (key) {
    case PaymentChannelType.AnindaHavale:
      return '56x';
    case PaymentChannelType.HavaleHera:
      return '58x';
    default:
      return '55x';
  }
};

export const getWithdrawInfo = (activeChannelId: PaymentChannelType) => {
  switch (activeChannelId) {
    case PaymentChannelType.BKash:
    case PaymentChannelType.ApayBkash:
    case PaymentChannelType.Nagad:
    case PaymentChannelType.ApayNagad:
    case PaymentChannelType.Rocket:
    case PaymentChannelType.EasypaisaFast:
    case PaymentChannelType.Easypaisa:
    case PaymentChannelType.JazzCash:
      return getWithdrawDefaultInfo();

    case PaymentChannelType.TRC:
    case PaymentChannelType.BEP:
    case PaymentChannelType.SOLANA:
    case PaymentChannelType.ERC:
      return getWithdrawCriptoInfo();

    case PaymentChannelType.BankPix:
    case PaymentChannelType.Pix:
      return getBankPixInfo();

    case PaymentChannelType.OctopayRocket:
    case PaymentChannelType.OctopayUPay:
    case PaymentChannelType.OctopayBKash:
    case PaymentChannelType.OctopayNagad:
      return getOctopayInfo();

    case PaymentChannelType.PixPagsmile:
    case PaymentChannelType.BankTransfer:
      return getPixPagsmileInfo();

    case PaymentChannelType.BTC:
    case PaymentChannelType.LTC:
    case PaymentChannelType.BCH:
    case PaymentChannelType.ADA:
    case PaymentChannelType.ETH:
    case PaymentChannelType.DOGE:
    case PaymentChannelType.XRP:
    case PaymentChannelType.USDTE:
    case PaymentChannelType.USDTT:
    case PaymentChannelType.BNB:
    case PaymentChannelType.BNBBSC:
    case PaymentChannelType.BUSD:
    case PaymentChannelType.USDC:
    case PaymentChannelType.TRX:
    case PaymentChannelType.DAI:
      return getCoinPayInfo();

    case PaymentChannelType.TurkishPapara:
    case PaymentChannelType.AnindaHavale:
    case PaymentChannelType.HavaleHera:
    case PaymentChannelType.TurkishExpressHavale:
    case PaymentChannelType.VipHavale:
      return getTurkishWithdrawInfo(getTurkishKey(activeChannelId));

    case PaymentChannelType.BkashMonetics:
    case PaymentChannelType.NagadMonetics:
      return getMoneticsWithdrawInfo();
    case PaymentChannelType.MPayFix:
    case PaymentChannelType.PaparaMPay:
    case PaymentChannelType.MPayPayco:
    case PaymentChannelType.MefeteMpay:
    case PaymentChannelType.EFTMPay:
    case PaymentChannelType.PopyMpay:
    case PaymentChannelType.MPayParazula:
      return getMPayWithdrawInfo();
    case PaymentChannelType.BKashFastWellexPay:
    case PaymentChannelType.NagadFastWellexPay:
    case PaymentChannelType.NagadFastWellexPayCopy:
    case PaymentChannelType.BKashFastWellexPayCopy:
    case PaymentChannelType.NagadWellexPay:
    case PaymentChannelType.WellxpayUpay:
    case PaymentChannelType.BkashWellexPay:
    case PaymentChannelType.WellxpayRocket:
    case PaymentChannelType.BkashCashX: // Test
    case PaymentChannelType.NagadCashX: // Test
    case PaymentChannelType.BkashXoomPay:
    case PaymentChannelType.NagadXoomPay:
    case PaymentChannelType.GLDNBKash:
      return getWellexPayWithdrawInfo();
    case PaymentChannelType.CreditCardAcquirning:
    case PaymentChannelType.CreditCard:
    case PaymentChannelType.CreditCardFast:
    case PaymentChannelType.WithdrawRubSber:
    case PaymentChannelType.WithdrawRubTinkof:
      return getRublesData();
    case PaymentChannelType.VivaroBkash:
    case PaymentChannelType.VivaroNagad:
      return getVivaroData();
    case PaymentChannelType.INRWithdrawImp:
      return withdrawInr();
    case PaymentChannelType.INRIMPS:
    case PaymentChannelType.INRNEFTS:
    case PaymentChannelType.INRRGTS:
      return withdrawInrOctoPay();
    default:
      break;
  }

  return null;
};
