import { useMemo, useState } from 'react';
import { useTransportControllers } from 'decryption_protocol_hooks';
import {
  ERequestIds,
  RecoverySubType,
} from 'decryption_protocol/dist/lib/binary/types';

import ComponentKitBaseButton from 'components/ui/KitComponents/Buttons/BaseButton';
import Input from 'components/ui/Input/Base';
import HOCRequestLoader from 'components/HOCs/HOCRequestLoader';
import PhoneInput from 'components/ui/Input/Phone';

import { DEFAULT_INPUT_VALUE } from 'components/ui/Input/Base/constant';

import { DefaultInputType } from 'components/ui/Input/Base/types';
import { composeValidations, isRequired } from 'utils/validation';

import styles from './LostPasswordPhoneForm.module.css';

const LostPasswordPhoneForm = () => {
  const [phone, setPhone] = useState<string>('');

  const [code, setCode] = useState<DefaultInputType>(DEFAULT_INPUT_VALUE);

  const { sendCommand } = useTransportControllers();

  const [isRequestedCode, setRequestedCodeStatus] = useState<boolean>(false);

  const callbackRequest = () => {
    setRequestedCodeStatus(true);
  };

  const submit = () => {
    sendCommand('31x', {
      sms: code.value,
      requestId: ERequestIds.RecoveryPasswordResponse,
    });
  };

  const isValidForm = useMemo(() => {
    const isPhone = phone;
    const isCode = code.value && !code.errorText;

    return isCode && isPhone;
  }, [phone, code]);

  const handleValidateCode = (value: string) => {
    const errorText = composeValidations([
      isRequired({ value, errorText: 'errors.required' }),
    ]);

    setCode({ value, errorText });
  };

  return (
    <div>
      <PhoneInput
        withConfirm
        requestId={ERequestIds.RecoveryPasswordSendCode}
        sendDataType={RecoverySubType.Phone}
        placeholder="popUp.recoveryPsw.phone"
        size="large"
        section={3}
        disabled={isRequestedCode}
        defaultValue=""
        onChange={setPhone}
        classes={isRequestedCode ? styles.formInput : styles.formPhoneWrapper}
        requestCallback={callbackRequest}
      />

      {isRequestedCode && (
        <Input
          placeholder="popUp.recoveryPsw.code"
          size="large"
          disabled={false}
          defaultValue=""
          onChange={handleValidateCode}
          classes={styles.formInput}
        />
      )}

      {isValidForm && (
        <HOCRequestLoader
          command="\\31x"
          requestId={ERequestIds.RecoveryPasswordResponse}
        >
          <ComponentKitBaseButton
            color="primary"
            size="--button-full"
            buttonHeight="--button-height-l"
            handleClick={submit}
            text="popUp.recoveryPsw.submitBtn"
            centreText
          />
        </HOCRequestLoader>
      )}
    </div>
  );
};

export default LostPasswordPhoneForm;
