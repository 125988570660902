import { useEffect, useState } from 'react';
import { useInstaNewsExperimental } from 'decryption_protocol_hooks';
import { InstaPost } from 'decryption_protocol/dist/lib/service/experimental/instaNews/types';

import Post from './components/Post';
import SlideProgress from './components/SlideProgress';

import { useZustandInstaNewsStore } from 'stores/instaNews/store';
import { getLastHistoryStoriesSelector } from 'stores/instaNews/selectors';
import { useZustandConnectionStore } from 'stores/connection/store';
import { isAvailableTransportSelector } from 'stores/connection/selectors';

import styles from './InstaNews.module.css';

const InstaNews = () => {
  const isConnectedApp = useZustandConnectionStore(
    isAvailableTransportSelector
  );

  const posts = useInstaNewsExperimental({ isConnectedApp }) as InstaPost[];

  const [activeSlide, setActiveSlide] = useState(0);

  const lastId = useZustandInstaNewsStore(getLastHistoryStoriesSelector);

  useEffect(() => {
    if (!lastId || !posts.length) {
      return;
    }
    const inx = posts.findIndex((p) => p.id === lastId);

    if (inx > 0) {
      setActiveSlide(inx);
    }
  }, [posts]);

  return (
    <div className={styles.wrapperInstaNews}>
      <div className={styles.content}>
        <SlideProgress
          activeSlide={activeSlide}
          setActiveSlide={setActiveSlide}
          posts={posts}
        />
        <div
          className={styles.slideArea}
          style={{ transform: `translateX(${activeSlide * -100}%)` }}
        >
          {posts.map((p) => (
            <Post {...p} id={p.id} key={p.id} />
          ))}

          <Post image="" id={posts[posts.length - 1]?.id} />
        </div>
      </div>
    </div>
  );
};

export default InstaNews;
