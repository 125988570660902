import React, { useMemo, useState } from 'react';
import { useTransportControllers } from 'decryption_protocol_hooks';
import { ERequestIds } from 'decryption_protocol/dist/lib/binary/types';

import Input from '../../KitComponents/Inputs/ComponentKitBaseInput';
import Timer from 'components/ui/Timer';
import ComponentKitBaseButton from 'components/ui/KitComponents/Buttons/BaseButton';

import { DEFAULT_INPUT_VALUE } from '../Base/constant';

import { DefaultInputType, InputBaseProps } from '../Base/types';

import { composeValidations, isEmail, isRequired } from 'utils/validation';

type Props = InputBaseProps &
  (
    | {
        withoutConfirmCode?: false | undefined;
        requestId: ERequestIds;
        classesWrapper?: string;
        requestCallback?: () => void;
      }
    | {
        withoutConfirmCode: true;
        classesWrapper?: string;
        requestId?: ERequestIds;
        requestCallback?: () => void;
      }
  );

const EmailInput = (props: Props) => {
  const {
    requestId,
    children,
    classesWrapper,
    requestCallback,
    onChange,
    withoutConfirmCode,
  } = props;

  const [email, setEmail] = useState<DefaultInputType>(DEFAULT_INPUT_VALUE);

  const [isRequestedCode, setRequestedCodeStatus] = useState<boolean>(false);

  const isValidEmail = useMemo(() => email.value && !email.errorText, [email]);

  const { sendCommand } = useTransportControllers();

  const handleRequestCode = () => {
    if (withoutConfirmCode) {
      return;
    }
    const baseData = {
      appType: process.env.REACT_APP_TYPE || 0,
      value: email.value,
      requestId,
    };

    const baseKey = '10x';

    sendCommand(baseKey, baseData);
  };

  const handleSetValue = (value: string) => {
    const errorText = composeValidations([
      isRequired({ value, errorText: 'errors.required' }),
      isEmail({ value, errorText: 'errors.email' }),
    ]);

    setEmail({ value, errorText });

    if (!errorText) {
      onChange(value);

      return;
    }
    onChange('');
  };

  const showTimer = () => {
    handleRequestCode();
    setRequestedCodeStatus(true);
    requestCallback?.();
  };

  return (
    <div className={classesWrapper}>
      <Input
        {...props}
        onChange={handleSetValue}
        errorText={email.errorText}
        inputMode="email"
      >
        {children}
      </Input>

      {!withoutConfirmCode && isRequestedCode && (
        <Timer handleRequestCode={handleRequestCode} />
      )}

      {!withoutConfirmCode && !isRequestedCode && (
        <ComponentKitBaseButton
          color={isValidEmail ? 'primary' : 'dark'}
          handleClick={showTimer}
          disabled={!isValidEmail}
          size="--button-full"
          buttonHeight="--button-height-l"
          text="getCodeBtn"
          centreText
        />
      )}
    </div>
  );
};

export default EmailInput;
