import { useGetActiveTournamentPlayers } from 'decryption_protocol_hooks';
import { ModalLayout, useTranslate } from 'decryption_component-kit';
import { TournamentPlayer } from 'decryption_protocol/dist/lib/store/casino/entities/casinoTournaments/types';

import TournamentPlayerRow from 'components/ui/TournamentPlayer';

import { closeAndOpenModalAction } from 'stores/modal/selectors';
import { ModalTypes } from 'stores/modal/types';
import { useZustandModalStore } from 'stores/modal/store';
import { useZustandConnectionStore } from 'stores/connection/store';
import { isAvailableTransportSelector } from 'stores/connection/selectors';

import styles from './CasinoTournamentPlayers.module.css';

const CasinoTournamentPlayers = () => {
  const closeModal = useZustandModalStore(closeAndOpenModalAction);

  const isConnectedApp = useZustandConnectionStore(
    isAvailableTransportSelector
  );

  const players = useGetActiveTournamentPlayers({
    isConnectedApp,
  }) as TournamentPlayer[];

  const { name: title } = useTranslate('tournament.players.title', '');

  const onClose = () => {
    closeModal(ModalTypes.Unknown);
  };

  return (
    <ModalLayout
      popUpHeaderProps={{
        title,
      }}
      buttonClose={onClose}
    >
      <>
        <div className={styles.playerContent}>
          {players.map((player, inx) => (
            <div key={player.id} className={styles.wrapperPlayer}>
              <TournamentPlayerRow position={inx + 1} {...player} />
            </div>
          ))}
        </div>
      </>
    </ModalLayout>
  );
};

export default CasinoTournamentPlayers;
