import { useEffect, useState } from 'react';
import { ERequestIds } from 'decryption_protocol/dist/lib/binary/types';

import {
  CashXMethods,
  PaymentChannelType,
  WellexPayMethods,
} from 'decryption_protocol/dist/lib/store/user/types';
import { setFormsInfoAction } from 'stores/money/forms/selectors';
import { useZustandFormsStore } from 'stores/money/forms/store';
import { getUserCurrencyIdSelector } from 'stores/user/personal/selectors';
import { useZustandUserStore } from 'stores/user/personal/store';
import AmountInput from 'components/ui/Input/AmountInput';

import { setPaymentsCurrentStepAction } from 'stores/money/payments/selectors';
import { useZustandPaymentsStore } from 'stores/money/payments/store';
import { Steps } from 'stores/money/payments/types';

import withdrawStyles from '../Withdraw.module.css';
import PhoneInput from 'components/ui/Input/Phone';

type Props = {
  channelId:
    | WellexPayMethods
    | CashXMethods
    | PaymentChannelType.BkashXoomPay
    | PaymentChannelType.GLDNBKash
    | PaymentChannelType.NagadXoomPay;
  min: number;
  max: number;
};

const WellexPay = ({ channelId, min, max }: Props) => {
  const [amount, setAmount] = useState<string>('');

  const [accountNumber, setAccountNumber] = useState<string>('');

  const setStep = useZustandPaymentsStore(setPaymentsCurrentStepAction);

  const setFormInfo = useZustandFormsStore(setFormsInfoAction);

  const currencyId = useZustandUserStore(getUserCurrencyIdSelector);

  useEffect(() => {
    if (currencyId && amount && accountNumber) {
      setFormInfo({
        isDeposit: false,
        form: {
          amount,
          currencyId,
          accountNumber: accountNumber.slice(3),
          channelId,
          requestId: ERequestIds.WithdrawWellexPay,
        },
      });
      setStep(Steps.FilledForm);
    } else {
      setStep(Steps.Amount);
      setFormInfo(null);
    }
  }, [currencyId, channelId, amount, accountNumber]);

  return (
    <div>
      <AmountInput
        inputStyleType="border-colorful"
        inputLabelType="labelUp"
        placeholder="walletPopUp.forms.amount"
        defaultValue={amount}
        size="full"
        onChange={setAmount}
        isWithdraw
        isPresets={false}
        min={min}
        classes={withdrawStyles.marginInputBottom}
        max={max}
        wrapperClasses={withdrawStyles.marginInputBottom}
      />

      <PhoneInput
        countryCodeDefault="BD"
        withConfirm={false}
        inputStyleType="border-colorful"
        inputLabelType="labelUp"
        size="full"
        sendKey="09x"
        defaultValue="+880"
        onChange={setAccountNumber}
        baseValue="+880"
        classesWrapper={withdrawStyles.marginInputBottom}
        placeholder="walletPopUp.forms.phone"
      />
    </div>
  );
};

export default WellexPay;
