import { useEffect, useState } from 'react';
import cln from 'classnames';
import { useGetAvailableBonuses } from 'decryption_protocol_hooks';
import { ChannelType } from 'decryption_protocol/dist/lib/store/operations/types';

import Checkbox from 'components/ui/Checkbox';
import BonusContent from './components/BonusContent';
import BonusSlideArrow from './components/BonusSliderArrow';
import { Text } from 'decryption_component-kit';

import { useZustandFormsStore } from 'stores/money/forms/store';
import { setDepositBonusIdForActivateAction } from 'stores/money/forms/selectors';
import { useZustandConnectionStore } from 'stores/connection/store';
import { isAvailableTransportSelector } from 'stores/connection/selectors';

import styles from './AvailableBonuses.module.css';
import animation from 'styles/animation.module.css';

const AvailableBonuses = () => {
  const isConnectedApp = useZustandConnectionStore(
    isAvailableTransportSelector
  );

  const { bonusOnDeposit = [], freeSpins = [] } = useGetAvailableBonuses({
    isConnectedApp,
  });

  const [hideBonuses, setBonusesState] = useState(false);

  const [activeInx, setActiveInx] = useState<number>(0);

  const setBonusId = useZustandFormsStore(setDepositBonusIdForActivateAction);

  const withoutBonuses = !freeSpins.length && !bonusOnDeposit.length;

  const toggleState = () => {
    const newState = !hideBonuses;

    if (newState) {
      setBonusId(null);
    }
    setBonusesState(newState);
  };

  const changeIndx = (factor: number) => () => {
    setActiveInx(activeInx + factor);
  };

  if (withoutBonuses) {
    return null;
  }

  const operations = [...bonusOnDeposit, ...freeSpins];

  console.log('🚀 ~ AvailableBonuses ~ operations:', operations);

  return (
    <div className={styles.wrapperBonusSection}>
      <div
        className={cln(styles.wrapperAvailableBonuses, {
          [animation.animationSelectorReducing]: hideBonuses,
          [animation.animationSelectorGrowing]: !hideBonuses,
        })}
      >
        <BonusSlideArrow handleClick={changeIndx(-1)} disabled={!activeInx} />

        <div className={styles.bonusWrapper}>
          <div
            className={styles.visibleWrapper}
            style={{
              transform: `translateX(-${activeInx * 100}%)`,
            }}
          >
            {operations.map((o, inx) => (
              <BonusContent
                key={o.id}
                order={activeInx + 1}
                id={o.id}
                minDeposit={o.minDeposit}
                isActive={activeInx === inx && !hideBonuses}
                isBonusOnDeposit={
                  o.channelType === ChannelType.CasinoBonusDeposit
                }
                bonusPromotion={
                  o.channelType === ChannelType.CasinoBonusDeposit
                    ? `${o.bonusPromoValue} %`
                    : `${o.campaign?.quantity || -1} FS`
                }
              />
            ))}
          </div>
        </div>

        <BonusSlideArrow
          isRight
          handleClick={changeIndx(1)}
          disabled={activeInx === operations.length - 1}
        />
      </div>

      <div className={styles.wrapperCheckbox}>
        <Checkbox checked={hideBonuses} toggleState={toggleState} />

        <div className={styles.bonusCheckboxText}>
          <Text idT="walletPopUp.availableBonuses" />
        </div>
      </div>
    </div>
  );
};

export default AvailableBonuses;
