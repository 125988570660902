import { useEffect } from 'react';
import {
  useGetUserTournamentInfo,
  useTransportControllers,
} from 'decryption_protocol_hooks';

import CasinoTournamentPlayer from 'components/ui/TournamentPlayer';

import { ERequestIds } from 'decryption_protocol/dist/lib/binary/types';
import { CurrentTournamentPlayer } from 'decryption_protocol/dist/lib/store/casino/entities/casinoTournaments/types';

import { useZustandUserStore } from 'stores/user/personal/store';
import { getClientIdSelector } from 'stores/user/personal/selectors';
import { useZustandConnectionStore } from 'stores/connection/store';
import { isAvailableTransportSelector } from 'stores/connection/selectors';

type Props = {
  tournamentId: number;
};

const CasinoSignInTournamentPlayer = ({ tournamentId }: Props) => {
  const isConnectedApp = useZustandConnectionStore(
    isAvailableTransportSelector
  );

  const userInfo = useGetUserTournamentInfo({
    isConnectedApp,
    tournamentId,
  }) as CurrentTournamentPlayer | null;

  const currentUserId = useZustandUserStore(getClientIdSelector);

  const { sendCommand } = useTransportControllers();

  useEffect(() => {
    if (!currentUserId) {
      return;
    }

    sendCommand(
      '45x',
      {
        casinoTournamentId: tournamentId,
        requestId: ERequestIds.GetCurrentUserTournamentPlace,
      },
      true
    );
  }, [tournamentId, currentUserId]);

  if (!userInfo?.position || !currentUserId) {
    return null;
  }

  return <CasinoTournamentPlayer {...userInfo} id={currentUserId} isHideInfo />;
};

export default CasinoSignInTournamentPlayer;
