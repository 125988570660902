import cln from 'classnames';
import { useBettingSports } from 'decryption_protocol_hooks';
import { Sport } from 'decryption_protocol/dist/lib/store/betting/entities/sports/types';

import ComponentKitBaseButton from 'components/ui/KitComponents/Buttons/BaseButton';

import { getSportIcon } from 'utils/icons';

import { useZustandConnectionStore } from 'stores/connection/store';
import { isAvailableTransportSelector } from 'stores/connection/selectors';

import styles from './ListSports.module.css';

type Props = {
  handleChooseSport: (id: number) => () => void;
  isShortView?: boolean;
  chooseSportId: number | null;
  isPrematch: boolean;
};

const ListSports = (props: Props) => {
  const { handleChooseSport, isShortView, chooseSportId, isPrematch } = props;

  const isConnectedApp = useZustandConnectionStore(
    isAvailableTransportSelector
  );

  const sports = useBettingSports({ isPrematch, isConnectedApp }) as Sport[];

  return (
    <>
      {sports.map((s: any) => (
        <ComponentKitBaseButton
          color="secondary"
          text={isShortView ? '' : s.name}
          size={isShortView ? '--button-m' : '--button-full'}
          buttonHeight="--button-height-l"
          key={s.id}
          handleClick={handleChooseSport(s.id)}
          startIcon={getSportIcon(s.id)}
          classes={cln(styles.itemSportWrapper, {
            [styles.shortView]: isShortView,
            [styles.activeSport]: s.id === chooseSportId,
          })}
        />
      ))}
    </>
  );
};

export default ListSports;
