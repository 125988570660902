import { useGetCategoryInfo } from 'decryption_protocol_hooks';
import { isAvailableTransportSelector } from 'stores/connection/selectors';
import { useZustandConnectionStore } from 'stores/connection/store';

type Props = { categoryId: number; classes?: string };

// all styles from parents
const CategoryName = ({ classes, categoryId }: Props) => {
  const isConnectedApp = useZustandConnectionStore(
    isAvailableTransportSelector
  );

  const category: any = useGetCategoryInfo({ categoryId, isConnectedApp });

  return <div className={classes}>{category?.name}</div>;
};

export default CategoryName;
