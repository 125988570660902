import { useBettingSportsById } from 'decryption_protocol_hooks';
import { isAvailableTransportSelector } from 'stores/connection/selectors';
import { useZustandConnectionStore } from 'stores/connection/store';

type Props = {
  classes?: string;
  sportId: number;
};

const SportName = ({ sportId, classes }: Props) => {
  const isConnectedApp = useZustandConnectionStore(
    isAvailableTransportSelector
  );

  const sport = useBettingSportsById({
    sportId,
    isConnectedApp,
  });

  return <div className={classes}>{sport?.name}</div>;
};

export default SportName;
