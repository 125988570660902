import { useEffect } from 'react';
import cln from 'classnames';
import { useBetSlipControllers } from 'decryption_protocol_hooks';
import {
  Bet,
  EBetState,
} from 'decryption_protocol/dist/lib/store/betting/entities/betslip/types';

import EventInfo from '../Templates/EventInfo';
import Odd from 'components/Betting/Odd';
import BetSlipAmount from 'components/ui/Input/BetSlipAmount';

import { useZustandConnectionStore } from 'stores/connection/store';
import { isAvailableTransportSelector } from 'stores/connection/selectors';

import styles from './Ordinar.module.css';
import animation from 'styles/animation.module.css';

type Props = Bet & {
  classes?: string;
};

const Ordinar = (props: Props) => {
  const {
    isPrematch,
    eventUid,
    lineUid,
    outcomeId,
    betUid,
    classes,
    status,
    stake,
  } = props;

  const isConnectedApp = useZustandConnectionStore(
    isAvailableTransportSelector
  );

  const { setStake, removeBet }: any = useBetSlipControllers({
    isConnectedApp,
  });

  useEffect(() => {
    if (status !== EBetState.Accepted) {
      return;
    }

    setTimeout(() => {
      removeBet({ betUid })();
    }, 700);
  }, [status, betUid]);

  const isOrdinarError = status === EBetState.Error;

  const isExpiredOrdinarData = status === EBetState.EventOrLineNotFound;

  return (
    <div className={cln(styles.wrapperOrdinarInfo, classes)}>
      {isOrdinarError && (
        <div
          className={cln(styles.errorStatus, animation.animationFadeOutModal)}
        />
      )}
      {status === EBetState.Accepted && (
        <div
          className={cln(
            styles.acceptedStatus,
            animation.animationFadeOutModal
          )}
        />
      )}
      {status === EBetState.EventOrLineNotFound && (
        <div className={styles.eventOrLineNotFoundWrapper}>
          T.NOT FOUND LINE
        </div>
      )}
      <div className={cln(styles.row, styles.jsb, styles.infoBox)}>
        <EventInfo
          isPrematch={isPrematch}
          eventUid={eventUid}
          lineUid={lineUid}
          outcomeId={outcomeId}
          betUid={betUid}
        />
        <Odd
          isPrematch={isPrematch}
          eventUid={eventUid}
          lineUid={lineUid}
          outcomeId={outcomeId}
          isBetSlip
          classes={styles.oddValue}
        />
      </div>

      <BetSlipAmount
        isError={isOrdinarError}
        setStake={setStake}
        betUid={betUid}
        isPrematch={isPrematch}
        eventUid={eventUid}
        lineUid={lineUid}
        outcomeId={outcomeId}
        defaultStake={stake}
      />
    </div>
  );
};

export default Ordinar;
