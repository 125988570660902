import { Tournament } from 'decryption_protocol/dist/lib/store/betting/entities/tournaments/types';
import { useBettingTournamentById } from 'decryption_protocol_hooks';
import { isAvailableTransportSelector } from 'stores/connection/selectors';
import { useZustandConnectionStore } from 'stores/connection/store';

type Props = {
  tournamentId: number;
  classes?: string;
};

// all styles from parents
const TournamentName = ({ classes, tournamentId }: Props) => {
  const isConnectedApp = useZustandConnectionStore(
    isAvailableTransportSelector
  );

  const tournament = useBettingTournamentById({
    tournamentId,
    isConnectedApp,
  }) as Tournament | null;

  return <div className={classes}>{tournament?.name}</div>;
};

export default TournamentName;
