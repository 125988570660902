import {
  OneClick,
  useZustandLanguagesKitStore,
  languageSelectors,
} from 'decryption_component-kit';
import {
  RegistrationSubType,
  ERequestIds,
} from 'decryption_protocol/dist/lib/binary/types';
import {
  useGetCountryInfo,
  useTransportControllers,
} from 'decryption_protocol_hooks';
import { CountryInfo } from 'decryption_protocol/dist/lib/store/user/types';

import { useZustandRegistrationStore } from 'stores/forms/signUp/store';
import {
  getActiveCountryIdSelector,
  getActiveCurrencyIdSelector,
  getAvailableCurrenciesSelector,
  setActiveCountryIdAction,
  setActiveCurrencyIdAction,
} from 'stores/forms/signUp/selectors';
import { useZustandConnectionStore } from 'stores/connection/store';
import { isAvailableTransportSelector } from 'stores/connection/selectors';
import { getRegistrationData } from 'utils/transport/main';

import { getDocsSelector } from 'stores/configuration/selectors';
import { useZustandWebConfiguration } from 'stores/configuration/store';

import { getFromLocalStorage } from 'utils/localstorage';

import { CUSTOM_FAST_CURRENCY_ID } from 'constants/localstoreKeys';
import { useZustandRequestStore } from 'stores/request/store';
import { isRequestedCommandSelector } from 'stores/request/selector';

const OneClickKit = ({ isSelected }: { isSelected: boolean }) => {
  const { sendCommand } = useTransportControllers();

  const isRequested = useZustandRequestStore(
    isRequestedCommandSelector(ERequestIds.Registration)
  );

  const activeCountryId = useZustandRegistrationStore(
    getActiveCountryIdSelector
  );

  const activeCurrencyId = useZustandRegistrationStore(
    getActiveCurrencyIdSelector
  );

  const availableCurrencies = useZustandRegistrationStore(
    getAvailableCurrenciesSelector
  );

  const setActiveCurrencyId = useZustandRegistrationStore(
    setActiveCurrencyIdAction
  );

  const isAvailableTransport = useZustandConnectionStore(
    isAvailableTransportSelector
  );

  const activeLang = useZustandLanguagesKitStore(
    languageSelectors.getActiveLanguageNameKitSelector
  );

  const docs = useZustandWebConfiguration(getDocsSelector) || {};

  const setActiveCountryId = useZustandRegistrationStore(
    setActiveCountryIdAction
  );

  const isConnectedApp = useZustandConnectionStore(
    isAvailableTransportSelector
  );

  const countryInfo = useGetCountryInfo({
    isConnectedApp,
  }) as CountryInfo | null;

  const handleRegistration = (promoCode: string) => {
    if (!activeCurrencyId || !activeCountryId) {
      return;
    }

    const customCurrencyId = getFromLocalStorage(CUSTOM_FAST_CURRENCY_ID);

    const data = getRegistrationData({
      countryId: activeCountryId,
      currencyId: Number(customCurrencyId) || activeCurrencyId,
      registrationType: RegistrationSubType.OneClick,
      promoCode,

      countryName: `${activeCountryId}`, // TODO back end is not validate this value
    });

    sendCommand('05x', data, true);
  };

  return (
    <OneClick
      isSelected={isSelected}
      activeCountryId={activeCountryId}
      activeCurrencyId={activeCurrencyId}
      activeLang={activeLang}
      handleSubmit={handleRegistration}
      docs={docs}
      sendCommand={sendCommand}
      isAvailableTransport={isAvailableTransport}
      currencyProps={{
        activeCurrencyId,
        availableCurrencies,
        setActiveCurrencyId,
        countryInfo,
      }}
      countryProps={{
        activeCountryId,
        setActiveCountryId,
        countryInfo,
      }}
      isLoading={isRequested}
    />
  );
};

export default OneClickKit;
