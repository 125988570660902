import { useEffect, useState } from 'react';
import cln from 'classnames';
import { useTicketInfo, useTicketControllers } from 'decryption_protocol_hooks';
import { Ticket } from 'decryption_protocol/dist/lib/store/betting/entities/tickets/types';
import { ERequestIds } from 'decryption_protocol/dist/lib/binary/types';

import { CloseSvg, SettingsSvg } from 'decryption_component-kit';
import HOCRequestLoader from 'components/HOCs/HOCRequestLoader';
import Loader from './components/Loader';
import ParticularCashout from './components/ParticularCashout';
import ComponentKitBaseButton from 'components/ui/KitComponents/Buttons/BaseButton';

import { isAvailableTransportSelector } from 'stores/connection/selectors';
import { useZustandConnectionStore } from 'stores/connection/store';
import { addRequestKeyForLoaderAction } from 'stores/request/selector';
import { useZustandRequestStore } from 'stores/request/store';

import styles from './CashOutButton.module.css';

type Props = {
  ticketId: number;
  classes?: string;
};

const MIN_BET_MOCK = 1;

const CashOutButton = (props: Props) => {
  const { ticketId, classes } = props;

  const [isParticularMode, setParticularCashoutState] = useState(false);

  const isConnectedApp = useZustandConnectionStore(
    isAvailableTransportSelector
  );

  const [outputStake, setOutputStake] = useState<number | null>(null);

  const { ticketCashOut } = useTicketControllers({ isConnectedApp, ticketId });

  const addKey = useZustandRequestStore(addRequestKeyForLoaderAction);

  const ticketInfo = useTicketInfo({ isConnectedApp, ticketId }) as
    | (Ticket & { cashOutInfo: number | null })
    | null;

  const handleCashOut = () => {
    if (!outputStake) {
      return;
    }
    setParticularCashoutState(false);

    addKey(ERequestIds.CashOut);

    ticketCashOut({
      outputStake,
      remainingStake: cashOutInfo - outputStake,
    });
    console.info(
      {
        outputStake,
        remainingStake: cashOutInfo - outputStake,
      },
      '123'
    );
  };

  const handleSetCashoutValue = (value: number) => () => {
    setOutputStake(value);

    // setParticularCashoutState(false);
  };

  const toggleParticularCashoutState = (e: any) => {
    e.stopPropagation();

    setParticularCashoutState(!isParticularMode);
  };

  useEffect(() => {
    if (!ticketInfo || !ticketInfo.cashOutInfo) {
      if (outputStake) {
        setOutputStake(null);
      }

      return;
    }

    if (!outputStake && ticketInfo?.cashOutInfo) {
      setOutputStake(ticketInfo.cashOutInfo);
    }
  }, [ticketInfo, outputStake]);

  if (!outputStake || !ticketInfo || !ticketInfo.cashOutInfo) {
    return null;
  }

  const { cashOutInfo } = ticketInfo;

  const Icon = !isParticularMode ? SettingsSvg : CloseSvg;

  return (
    <>
      <div
        className={cln(styles.wrapperCashoutButtons, classes, {
          [styles.marginB]: isParticularMode,
        })}
      >
        <HOCRequestLoader
          requestId={ERequestIds.CashOut}
          command="28x"
          customLoader={<Loader cashOutInfo={outputStake} />}
        >
          <>
            <ComponentKitBaseButton
              text={`Cash Out ${outputStake.toFixed(2)}`}
              handleClick={handleCashOut}
              size="--button-full"
              color="primary"
              centreText
              buttonHeight="--button-height-full"
              classes={styles.cashoutBtn}
            />

            <Icon
              handleClick={toggleParticularCashoutState}
              classes={cln(styles.settingSvg, {
                [styles.disabled]: cashOutInfo - MIN_BET_MOCK < MIN_BET_MOCK,
              })}
            />
          </>
        </HOCRequestLoader>
      </div>

      {isParticularMode && (
        <ParticularCashout
          toggleParticularCashoutState={toggleParticularCashoutState}
          handleSetCashoutValue={handleSetCashoutValue}
          outputStake={outputStake}
          maxCashout={cashOutInfo}
        />
      )}
    </>
  );
};

export default CashOutButton;
