import { JackpotWidget } from 'decryption_component-kit';
import { useJackpotsExperimental } from 'decryption_protocol_hooks';

import { CustomJackpotInfo } from 'decryption_protocol/dist/lib/service/experimental/jackpots/types';

import { useZustandConnectionStore } from 'stores/connection/store';
import { isAvailableTransportSelector } from 'stores/connection/selectors';
import { useZustandModalStore } from 'stores/modal/store';
import { setModalInfoAction } from 'stores/modal/selectors';
import { ModalTypes } from 'stores/modal/types';

const JackpotWidgetComponentKit = ({ classes }: { classes?: string }) => {
  const isConnectedApp = useZustandConnectionStore(
    isAvailableTransportSelector
  );

  const jackpots: CustomJackpotInfo[] | null = useJackpotsExperimental({
    isConnectedApp,
    customPath: `/configs/themes/${
      process.env.REACT_APP_TYPE
    }/jackpots/${new Date().getMonth()}.json`,
  });

  const setModal = useZustandModalStore(setModalInfoAction);

  const handlePressWidgetCard = (jackpotId: number) => () => {
    setModal(ModalTypes.JackpotInfo, {
      id: jackpotId,
    });
  };

  if (!jackpots?.length) {
    return null;
  }

  return (
    <JackpotWidget
      jackpots={jackpots}
      classes={classes}
      handleClick={handlePressWidgetCard}
    />
  );
};

export default JackpotWidgetComponentKit;
