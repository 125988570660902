import { useBetSlipBets } from 'decryption_protocol_hooks';
import { Bet } from 'decryption_protocol/dist/lib/store/betting/entities/betslip/types';

import PlaceBetSlipButtons from 'components/ui/Buttons/PlaceBetSlipButtons';
import Express from './components/Express';
import Ordinar from './components/Ordinar';
import System from './components/System';

import { useZustandConnectionStore } from 'stores/connection/store';
import { isAvailableTransportSelector } from 'stores/connection/selectors';

import styles from './BetSlipContent.module.css';

const BetSlipContent = () => {
  const isConnectedApp = useZustandConnectionStore(
    isAvailableTransportSelector
  );

  const bets = useBetSlipBets({ isConnectedApp }) as Bet[];

  return (
    <div className={styles.wrapperBetSlipContent}>
      <div className={styles.betsContent}>
        {bets.map((bet: Bet) => (
          <Ordinar key={bet.betUid} {...bet} classes={styles.borderBottom} />
        ))}
      </div>
      {bets.length > 1 && <Express />}

      {bets.length > 2 && <System />}

      <PlaceBetSlipButtons classes={styles.placeBetWrapper} />
    </div>
  );
};

export default BetSlipContent;
