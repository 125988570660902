import { useMemo, useState } from 'react';

import CountrySelector from '../../CountrySelector';
import CurrencySelector from '../../CurrencySelector';
import Input from 'components/ui/Input/Base';
import { DEFAULT_INPUT_VALUE } from 'components/ui/Input/Base/constant';
import { DefaultInputType } from 'components/ui/Input/Base/types';

import styles from '../../../SignUp.module.css';
import { useTransportControllers } from 'decryption_protocol_hooks';
import {
  ERequestIds,
  RegistrationSubType,
} from 'decryption_protocol/dist/lib/binary/types';
import {
  getActiveCountryIdSelector,
  getActiveCurrencyIdSelector,
  setRegisteredLoginAction,
} from 'stores/forms/signUp/selectors';
import { useZustandRegistrationStore } from 'stores/forms/signUp/store';
import { getRegistrationData } from 'utils/transport/main';
import HOCRequestLoader from 'components/HOCs/HOCRequestLoader';

import PhoneInput from 'components/ui/Input/Phone';
import {
  Text,
  useZustandLanguagesKitStore,
  languageSelectors,
  useTranslate,
} from 'decryption_component-kit';
import { useZustandWebConfiguration } from 'stores/configuration/store';
import { getDocsSelector } from 'stores/configuration/selectors';

import DocsCheckBox from '../DocsCheckBox';
import BaseButton from 'components/ui/KitComponents/Buttons/BaseButton';
import {
  PHONE_PROPS_WITHOUT_CONFIRMATION,
  PHONE_PROPS_WITH_CONFIRMATION,
} from './constants';
import RegistrationPromoCode from '../../PromoCode';
import { openExternalLink } from 'utils/shared';

const Phone = ({
  isSelected,
  withoutCode = false,
}: {
  isSelected: boolean;
  withoutCode?: boolean;
}) => {
  const setRegisteredLogin = useZustandRegistrationStore(
    setRegisteredLoginAction
  );
  const { sendCommand } = useTransportControllers();

  const { getText } = useTranslate('', '');

  const [phone, setPhone] = useState<string>('');

  const [code, setCode] = useState<DefaultInputType>(
    withoutCode
      ? {
          value: '  ',
          errorText: '',
        }
      : DEFAULT_INPUT_VALUE
  );

  const [isRequestedCode, setRequestedCodeState] =
    useState<boolean>(withoutCode);

  const activeLang = useZustandLanguagesKitStore(
    languageSelectors.getActiveLanguageNameKitSelector
  );

  const docs = useZustandWebConfiguration(getDocsSelector) || {};

  const activeCountryId = useZustandRegistrationStore(
    getActiveCountryIdSelector
  );

  const activeCurrencyId = useZustandRegistrationStore(
    getActiveCurrencyIdSelector
  );

  const [password, setPassword] =
    useState<DefaultInputType>(DEFAULT_INPUT_VALUE);

  const [terms, setTermsState] = useState(isSelected);

  const [age, setAgeState] = useState(isSelected);

  const isPhoneError = Boolean(!phone);

  const isDisabledSubmitButton = useMemo(
    () =>
      Boolean(
        isPhoneError ||
          !password.value ||
          password.errorText ||
          code.errorText ||
          !code.value ||
          !isRequestedCode ||
          !activeCountryId ||
          !activeCurrencyId ||
          !terms ||
          !age
      ),
    [
      isPhoneError,
      password,
      code,
      isRequestedCode,
      activeCountryId,
      activeCurrencyId,
      age,
      terms,
    ]
  );

  const [promoCode, setPromoCode] = useState('');

  const handleIsRequested = () => {
    setRequestedCodeState(true);
  };

  const handleChange = (t: 'code' | 'password') => (value: string) => {
    if (t === 'code') {
      setCode({ value, errorText: '' });
    }

    if (t === 'password') {
      setPassword({ value, errorText: '' });
    }
  };

  const handleSubmitForm = () => {
    if (!activeCountryId || !activeCurrencyId) {
      return;
    }

    const sendData = {
      ...getRegistrationData({
        currencyId: activeCurrencyId,
        countryId: activeCountryId,
        registrationType: withoutCode
          ? RegistrationSubType.PhoneWithoutCode
          : RegistrationSubType.Phone,
        promoCode,

        countryName: '', // TODO back not validate this value
      }),
      letter: 0,
      login: phone,
      email: '',
      password: password.value,
      sms: code.value,
      requestId: ERequestIds.Registration,
    };

    setRegisteredLogin(phone);

    sendCommand('08x', sendData, true);
  };

  const handleToggleState = (name: 'terms' | 'age') => () => {
    const toggleFn = name === 'terms' ? setTermsState : setAgeState;
    const oldState = name === 'terms' ? terms : age;

    toggleFn(!oldState);
  };

  const handleOpenDoc = () => {
    if (!activeLang) {
      return;
    }
    // TODO terms
    const link = docs[1]?.link.replace('[lang]', activeLang);

    if (!link) {
      return;
    }

    openExternalLink(link, true);
  };

  const PHONE_PROPS = withoutCode
    ? PHONE_PROPS_WITHOUT_CONFIRMATION
    : PHONE_PROPS_WITH_CONFIRMATION;

  return (
    <div>
      <PhoneInput
        {...PHONE_PROPS}
        placeholder="registrations.inputs.phone"
        size="full"
        defaultValue="+"
        onChange={setPhone}
        requestCallback={handleIsRequested}
        classesWrapper={styles.marginB}
        disabled={isRequestedCode}
        classes={styles.marginB}
      />
      {!withoutCode && (
        <Input
          placeholder="registrations.inputs.code"
          size="full"
          isHidePlaceholder
          disabled={!isRequestedCode}
          onChange={handleChange('code')}
          classes={styles.marginB}
        />
      )}
      <Input
        placeholder="registrations.inputs.password"
        size="full"
        isHidePlaceholder
        onChange={handleChange('password')}
        classes={styles.marginB}
      />
      <RegistrationPromoCode setPromoCode={setPromoCode} />

      <CountrySelector />

      <CurrencySelector />

      <DocsCheckBox
        terms={terms}
        age={age}
        handleOpenDoc={handleOpenDoc}
        handleToggleState={handleToggleState}
      />
      <HOCRequestLoader
        command="'\\08x'"
        requestId={ERequestIds.Registration}
        customLoader={
          <div
            style={{ height: ' var(--height-48)' }}
            className={styles.marginB}
          >
            <Text idT="loading" />
          </div>
        }
      >
        <BaseButton
          text={getText('registrations.submitBtn')}
          disabled={isDisabledSubmitButton}
          color="primary"
          handleClick={handleSubmitForm}
          size="--button-full"
          centreText
          buttonHeight="--button-height-full"
          classes={styles.marginB}
        />
      </HOCRequestLoader>
    </div>
  );
};

export default Phone;
