import cln from 'classnames';
import {
  useBetSlipControllers,
  useBetSlipBets,
} from 'decryption_protocol_hooks';

import { Selector } from 'decryption_component-kit';

import { useZustandConnectionStore } from 'stores/connection/store';
import { isAvailableTransportSelector } from 'stores/connection/selectors';
import {
  EBetState,
  ECustomBetUid,
  ReturnBetSlipControllerTypes,
  SystemBet,
} from 'decryption_protocol/dist/lib/store/betting/entities/betslip/types';

import styles from './System.module.css';
import betslipStyles from '../../BetSlipContent.module.css';
import BetSlipAmount from 'components/ui/Input/BetSlipAmount';
import { useMemo } from 'react';
import SystemOdd from './components/SystemOdd';

const System = () => {
  const isConnectedApp = useZustandConnectionStore(
    isAvailableTransportSelector
  );

  const { setStake, setSystemRang } = useBetSlipControllers({
    isConnectedApp,
  }) as ReturnBetSlipControllerTypes;

  const systemBet = useBetSlipBets({
    isConnectedApp,
    betUid: ECustomBetUid.System,
  }) as SystemBet | [];

  const selectorData = useMemo(() => {
    if (!systemBet || Array.isArray(systemBet)) {
      return [];
    }

    return new Array(systemBet.betLength - 2).fill(0).map((_, inx) => ({
      id: inx + 2,
      name: `${inx + 2}/${systemBet.betLength}`,
    }));
  }, [systemBet]);

  if (!systemBet || Array.isArray(systemBet)) {
    return null;
  }

  const isDisabled = systemBet.status === EBetState.EventOrLineNotFound;

  return (
    <div
      className={cln(styles.wrapperSystem, {
        [styles.opacity]: isDisabled,
      })}
    >
      <div className={betslipStyles.leftSide}>
        <Selector
          size="small"
          activeId={systemBet.activeSystemRange}
          data={selectorData}
          handleClick={setSystemRang}
          isOpenUp
        />

        {/* TODO: make separete module for this logic */}
        <div className={betslipStyles.oddWrapper}>
          <SystemOdd />
          {/* {systemBet?.odd} */}
        </div>
      </div>
      <BetSlipAmount
        isError={false}
        setStake={setStake}
        betUid={ECustomBetUid.System}
        isPrematch
        eventUid="-"
        lineUid="-"
        outcomeId={-1}
        defaultStake={systemBet?.stake}
      />
    </div>
  );
};

export default System;
