import cln from 'classnames';
import {
  useBetSlipBets,
  useBetSlipControllers,
} from 'decryption_protocol_hooks';

import {
  ReturnBetSlipControllerTypes,
  EBetState,
  ECustomBetUid,
  ExpressBet,
} from 'decryption_protocol/dist/lib/store/betting/entities/betslip/types';

import BetSlipAmount from 'components/ui/Input/BetSlipAmount';

import { useZustandConnectionStore } from 'stores/connection/store';
import { isAvailableTransportSelector } from 'stores/connection/selectors';

import betSlipStyles from '../../BetSlipContent.module.css';
import styles from './Express.module.css';
import ExpressOdd from './components/ExpressOdd';

const Express = () => {
  const isConnectedApp = useZustandConnectionStore(
    isAvailableTransportSelector
  );

  const { setStake } = useBetSlipControllers({
    isConnectedApp,
  }) as ReturnBetSlipControllerTypes;

  const expressBet: any = useBetSlipBets({
    isConnectedApp,
    betUid: ECustomBetUid.Express,
  }) as ExpressBet | null;

  if (!expressBet) {
    return null;
  }

  const isError = expressBet.status === EBetState.Error;

  const isDisabled = expressBet.status === EBetState.EventOrLineNotFound;

  return (
    <div
      className={cln(styles.wrapperExpress, {
        [styles.opacity]: isDisabled,
      })}
    >
      <div className={betSlipStyles.leftSide}>
        <div>
          <div className={styles.title}>T.Express bet</div>
          <div className={styles.betCount}>T.bets {expressBet.betLength}</div>
        </div>
        {/* TODO: make separete module for this logic */}
        <div className={betSlipStyles.oddWrapper}>
          <ExpressOdd />
        </div>
      </div>

      <BetSlipAmount
        eventUid="-"
        betUid={ECustomBetUid.Express}
        setStake={setStake}
        isPrematch
        isError={isError}
        lineUid="-"
        outcomeId={-1}
        defaultStake={expressBet.stake}
      />
    </div>
  );
};

export default Express;
