import { useNavigate } from 'react-router';
import { Text, RecommendedSvg } from 'decryption_component-kit';
import { useGetProviderInfoByIdOrName } from 'decryption_protocol_hooks';
import { IProvider } from 'decryption_protocol/dist/lib/store/casino/entities/providers/types';

import { useZustandConnectionStore } from 'stores/connection/store';
import { isAvailableTransportSelector } from 'stores/connection/selectors';

import RowGames from '../CasinoCategoryRow/components/RowGames';
import { ERouteNames } from 'Routes/types';

import styles from './CasinoRecommendedGames.module.css';
import { ECasinoCategoryIds } from 'decryption_protocol/dist/lib/store/casino/entities/categories/types';
import CasinoCategoryRow from '../CasinoCategoryRow';

type Props = {
  providerId: number;
};

const RecommendedGames = ({ providerId }: Props) => {
  const navigate = useNavigate();

  const isConnectedApp = useZustandConnectionStore(
    isAvailableTransportSelector
  );

  const provider = useGetProviderInfoByIdOrName({
    providerId,
    isConnectedApp,
  }) as IProvider;

  const jumpToProvider = () => {
    const path = ERouteNames.CasinoProviderGames.replaceAll(
      ':id',
      `${providerId}`
    );

    navigate(path);
  };

  return (
    <div>
      <div className={styles.recommendedTitle}>
        <RecommendedSvg classes={styles.recommendedGamesSvg} />

        <div className={styles.title}>
          <Text idT="recommendedGames" specValue={provider?.name} />
        </div>
      </div>

      <RowGames
        hookProps={{
          categoryId: null,
          subCategoryId: null,
          page: 0,
          search: '',
          providerId,
        }}
        jumpTo={jumpToProvider}
        classes={styles.marginB}
      />

      <CasinoCategoryRow
        categoryId={ECasinoCategoryIds.Popular}
        subCategoryId={0}
        name="mostPopular"
        disableShowMoreBtn
        rowHeaderClasses={styles.color}
      />
    </div>
  );
};

export default RecommendedGames;
