import { useState } from 'react';
import {
  useUserAvatars,
  useUserAvatarControllers,
} from 'decryption_protocol_hooks';
import {
  AvatarCollectionType,
  AvatarType,
} from 'decryption_protocol/dist/lib/store/user/entities/avatar/types';
import { ModalLayout, useTranslate } from 'decryption_component-kit';

import AvatarCollection from './components/AvatarCollection';
import ComponentKitBaseButton from 'components/ui/KitComponents/Buttons/BaseButton';

import { useZustandModalStore } from 'stores/modal/store';
import { closeAndOpenModalAction } from 'stores/modal/selectors';
import { ModalTypes } from 'stores/modal/types';
import { useZustandConnectionStore } from 'stores/connection/store';
import { isAvailableTransportSelector } from 'stores/connection/selectors';

const Avatars = () => {
  const closeModal = useZustandModalStore(closeAndOpenModalAction);

  const { name: title } = useTranslate('kgames.avatars.title', '');

  const [preselectedAvatarInfo, setPreselectedAvatarInfo] =
    useState<AvatarType | null>(null);

  const isConnectedApp = useZustandConnectionStore(
    isAvailableTransportSelector
  );

  const avatars = useUserAvatars({ isConnectedApp }) as AvatarCollectionType[];

  const { handleSaveAvatar } = useUserAvatarControllers({ isConnectedApp });

  const onCloseModal = () => {
    closeModal(ModalTypes.Unknown);
  };

  const handleChoose = (info: AvatarType) => () => {
    const newAvatar = info.id === preselectedAvatarInfo?.id ? null : info;

    setPreselectedAvatarInfo(newAvatar);
  };

  const handleSetAvatar = () => {
    handleSaveAvatar(preselectedAvatarInfo);
  };

  return (
    <ModalLayout
      popUpHeaderProps={{
        title,
      }}
      buttonClose={onCloseModal}
    >
      <>
        {avatars.map((a: any) => (
          <AvatarCollection
            {...a}
            key={a.name}
            selectedAvatarId={preselectedAvatarInfo?.id}
            handleChoose={handleChoose}
          />
        ))}

        <ComponentKitBaseButton
          text="kgames.avatars.save"
          size="--button-full"
          buttonHeight="--button-height-xl"
          color="primary"
          disabled={preselectedAvatarInfo === null}
          handleClick={handleSetAvatar}
          centreText
        />
      </>
    </ModalLayout>
  );
};

export default Avatars;
