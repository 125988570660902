import { useEffect, useState } from 'react';
// target use REF
const useInView = (
  // @ts-ignore
  target: MutableRefObject<T>,
  options?: IntersectionObserverInit
) => {
  const { root, rootMargin, threshold } = options || {};

  const [isIntersecting, setIsIntersecting] = useState(false);

  const [observer, setObserver] = useState<null | IntersectionObserver>(null);

  useEffect(() => {
    const callback = (entries: IntersectionObserverEntry[]) => {
      setIsIntersecting(entries[0].isIntersecting);
    };

    observer?.disconnect();

    if (target.current) {
      const _observer = new IntersectionObserver(callback, options);

      _observer?.observe(target.current);
      setObserver(_observer);
    }

    return () => {
      observer?.disconnect();
    };
  }, [target.current, root, rootMargin, threshold]);

  return isIntersecting;
};

export default useInView;
