import { useEffect } from 'react';
import cln from 'classnames';
import { useGetCountryInfo } from 'decryption_protocol_hooks';

import { CountryInfo } from 'decryption_protocol/dist/lib/store/user/types';

import { Selector } from 'decryption_component-kit';

import { useZustandRegistrationStore } from 'stores/forms/signUp/store';

import {
  getActiveCurrencyIdSelector,
  getAvailableCurrenciesSelector,
  setActiveCurrencyIdAction,
} from 'stores/forms/signUp/selectors';
import { useZustandConnectionStore } from 'stores/connection/store';
import { isAvailableTransportSelector } from 'stores/connection/selectors';

import styles from '../../SignUp.module.css';

type Props = {
  classes?: string;
};

const CurrencySelector = ({ classes }: Props) => {
  const activeCurrencyId = useZustandRegistrationStore(
    getActiveCurrencyIdSelector
  );

  const availableCurrencies = useZustandRegistrationStore(
    getAvailableCurrenciesSelector
  );

  const setActiveCurrencyId = useZustandRegistrationStore(
    setActiveCurrencyIdAction
  );

  const isConnectedApp = useZustandConnectionStore(
    isAvailableTransportSelector
  );

  const countryInfo = useGetCountryInfo({
    isConnectedApp,
  }) as CountryInfo | null;

  useEffect(() => {
    if (activeCurrencyId || !countryInfo) {
      return;
    }

    const isExist = availableCurrencies.find((c) =>
      countryInfo.currencyIds.includes(c.id)
    );

    if (isExist) {
      setActiveCurrencyId(countryInfo.currencyIds[0]);

      return;
    }

    if (!availableCurrencies[0]) {
      return;
    }

    setActiveCurrencyId(availableCurrencies[0].id);
  }, [availableCurrencies, activeCurrencyId, countryInfo]);

  return (
    <Selector
      classes={cln(styles.marginB, classes)}
      // isOpenUp
      size="full"
      placeholder="registrations.selectors.currency"
      handleClick={setActiveCurrencyId}
      activeId={activeCurrencyId}
      data={availableCurrencies}
    />
  );
};

export default CurrencySelector;
