import { useEffect, useRef } from 'react';
import cln from 'classnames';
import {
  useOdd,
  useBetSlipControllers,
  useBetSlipIsSelectedOdd,
} from 'decryption_protocol_hooks';
import { OddSubscriberResponse } from 'decryption_protocol/dist/lib/store/betting/entities/events/types';

import Indicator from './components/Indicator';
import OddAnimation from './components/OddAnimation';

import { useZustandConnectionStore } from 'stores/connection/store';
import { isAvailableTransportSelector } from 'stores/connection/selectors';

import styles from './Odd.module.css';
import { ReturnGetOddType } from 'decryption_protocol/dist/lib/store/betting/services/types';

type Props = {
  eventUid: string;
  outcomeId: number;
  lineUid: string;
  isPrematch?: boolean;

  isBetSlip?: boolean;
  startTextClasses?: string;
  classes?: string;
  startText?: string;
};

const Odd = (props: Props) => {
  const {
    startTextClasses,
    classes,
    startText,
    eventUid,
    isPrematch = false,
    isBetSlip = false,
    lineUid,
    outcomeId,
  } = props;

  const isConnectedApp = useZustandConnectionStore(
    isAvailableTransportSelector
  );

  const oddInfo = useOdd({
    lineUid,
    eventUid,
    isPrematch,
    outcomeId,
    isConnectedApp,
  }) as OddSubscriberResponse;

  const { addBet, removeBet }: any = useBetSlipControllers({ isConnectedApp });

  const isSelected = useBetSlipIsSelectedOdd({
    lineUid,
    eventUid,
    outcomeId,
    isConnectedApp,
  });

  const { odd } = oddInfo;

  const prevOddInfo = useRef<OddSubscriberResponse>(oddInfo);

  useEffect(() => {
    if (oddInfo.odd !== prevOddInfo.current.odd) {
      prevOddInfo.current = oddInfo;
    }
  }, [oddInfo, prevOddInfo]);

  const prevOdd = prevOddInfo.current.odd || 0;

  const handleClickMethod = isSelected ? removeBet : addBet;

  return (
    <div
      className={cln(styles.odd, classes, {
        // TODO initial selected
        [styles.selected]: isSelected,
        [styles.disabled]: !odd || Boolean(oddInfo.status),
      })}
      onClick={
        !isBetSlip
          ? handleClickMethod({
              lineUid,
              eventUid,
              isPrematch,
              outcomeId,
            })
          : undefined
      }
    >
      <Indicator
        // TODO use key eventUid_lineUid_outcomeId_outcomeIdState_odd ???
        // key={`${odd}_${prevOddInfo.current.odd}`}
        isAnimate={prevOdd !== prevOddInfo.current.odd}
        isGreen={odd !== prevOdd && prevOdd !== 0 ? odd > prevOdd : false}
        isRed={odd !== prevOdd && prevOdd !== 0 ? odd < prevOdd : false}
      />
      {startText && <div className={startTextClasses}>{startText}</div>}

      <OddAnimation odd={odd} />
    </div>
  );
};

export default Odd;
