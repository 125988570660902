import {
  useShortLineInfo,
  useBetSlipControllers,
} from 'decryption_protocol_hooks';

import { CloseSvg } from 'decryption_component-kit';

import { useZustandConnectionStore } from 'stores/connection/store';
import { isAvailableTransportSelector } from 'stores/connection/selectors';

import { ReturnSubscribeLineEntitiesProps } from 'decryption_protocol/dist/lib/store/betting/entities/events/types';

import styles from './EventInfo.module.css';

type Props = {
  isPrematch: boolean;
  eventUid: string;
  betUid: string;
  lineUid: string;
  outcomeId: number;
};

const EventInfo = (props: Props) => {
  const { isPrematch, eventUid, lineUid, outcomeId, betUid } = props;

  const isConnectedApp = useZustandConnectionStore(
    isAvailableTransportSelector
  );

  const shortLineInfo = useShortLineInfo({
    lineUid,
    eventUid,
    isPrematch,
    outcomeId,
    isConnectedApp,
  }) as ReturnSubscribeLineEntitiesProps;

  const { removeBet }: any = useBetSlipControllers({ isConnectedApp });

  return (
    <div className={styles.ordinarInfo}>
      <CloseSvg classes={styles.closeSvg} handleClick={removeBet({ betUid })} />

      <div>
        <div className={styles.nameEvent}>{shortLineInfo.favoriteTeam}</div>
        <div className={styles.marketName}>{shortLineInfo.marketName}</div>
      </div>
      <div className={styles.teamNames}>
        {shortLineInfo.homeName}-{shortLineInfo.awayName}
      </div>
    </div>
  );
};

export default EventInfo;
