import { ERouteNames } from 'Routes/types';
import { LotteryTypes } from 'stores/lottery/types';

export const BIG_APP_BUTTONS = [
  {
    type: 'casino',
    text: 'drawers.casino.title',
    to: ERouteNames.CasinoHome,
  },
  {
    type: 'betting',
    text: 'drawers.betting.title',
    to: ERouteNames.FrameBetting,
    // : ERouteNames.BettingLive}
  },
  {
    type: 'lottery',
    text: 'drawers.casino.lottery',
    to: ERouteNames.Lottery.replace(':type', LotteryTypes[0]),
  },
];
