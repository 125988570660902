import { useNavigate } from 'react-router';
import { useBettingTournamentsByCategoryIdOrSportId } from 'decryption_protocol_hooks';

import TournamentName from 'components/Betting/Names/TournamentName';

import { useZustandConnectionStore } from 'stores/connection/store';
import { isAvailableTransportSelector } from 'stores/connection/selectors';
import { useZustandEventTimeFilterStore } from 'stores/betting/filters/TimeFilter/store';
import { getActiveTimeFilterEventsSelector } from 'stores/betting/filters/TimeFilter/selectors';
import { ModalTypes } from 'stores/modal/types';
import { useZustandModalStore } from 'stores/modal/store';
import { setModalInfoAction } from 'stores/modal/selectors';

import { ERouteNames } from 'Routes/types';

import styles from './CategoryTournamentList.module.css';

type Props = {
  isPrematch: boolean;
  categoryId: number;
};

const CategoryTournamentList = ({ categoryId, isPrematch }: Props) => {
  const isConnectedApp = useZustandConnectionStore(
    isAvailableTransportSelector
  );

  const time = useZustandEventTimeFilterStore(
    getActiveTimeFilterEventsSelector
  );

  const setModal = useZustandModalStore(setModalInfoAction);

  const navigate = useNavigate();

  const tournaments = useBettingTournamentsByCategoryIdOrSportId({
    categoryId,
    isPrematch,
    time: isPrematch ? time : null,
    isConnectedApp,
  });

  const jumpTo = (id: number) => () => {
    navigate(ERouteNames.BettingTournament.replace(':tournamentId', `${id}`));

    setModal(ModalTypes.Unknown);
  };

  return (
    <div className={styles.wrapperTournamentsByCategoryId}>
      {tournaments.map(({ id }: any) => (
        <div key={id} className={styles.wrapperTournament} onClick={jumpTo(id)}>
          <div className={styles.icon} />

          <TournamentName tournamentId={id} classes={styles.tournamentName} />
        </div>
      ))}
    </div>
  );
};

export default CategoryTournamentList;
