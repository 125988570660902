import { useState } from 'react';
import ReactDOM from 'react-dom';
import { useNavigate } from 'react-router-dom';
import cln from 'classnames';
import { useBalance } from 'decryption_protocol_hooks';
import { BalanceInfo } from 'decryption_protocol/dist/lib/store/user/entities/balance/types';
import {
  DepositButton,
  useTranslate,
  ArrowSvg,
} from 'decryption_component-kit';

import BalanceList from './components/BalanceList';
import DesktopProfile from './components/DesktopProfileView';

import { useZustandModalStore } from 'stores/modal/store';
import { setModalInfoAction } from 'stores/modal/selectors';

import { useZustandUserStore } from 'stores/user/personal/store';
import { getUserCurrencyNameSelector } from 'stores/user/personal/selectors';
import { isMobileDeviceSelector } from 'stores/device/selectors';
import { useZustandDeviceStore } from 'stores/device/store';
import { useZustandConnectionStore } from 'stores/connection/store';
import { isAvailableTransportSelector } from 'stores/connection/selectors';

import { ERouteNames } from 'Routes/types';
import { ModalTypes } from 'stores/modal/types';

import { sleep } from 'utils/shared';

import { APP_TYPE } from 'constants/ui';

import styles from './ProfileInfo.module.css';
import animation from 'styles/animation.module.css';

const ProfileInfo = ({
  isCasinoGameHeader,
}: {
  isCasinoGameHeader: boolean;
}) => {
  const isMobile = useZustandDeviceStore(isMobileDeviceSelector);

  const isConnectedApp = useZustandConnectionStore(
    isAvailableTransportSelector
  );

  const balanceInfo = useBalance({
    isConnectedApp,
    isBalanceInfo: true,
  }) as BalanceInfo | null;

  const setModalInfo = useZustandModalStore(setModalInfoAction);

  const navigate = useNavigate();

  const { getText } = useTranslate('', '');

  const currencyName = useZustandUserStore(getUserCurrencyNameSelector);

  const [isPopUpBalance, setPopUpBalanceState] = useState<boolean>(false);

  const [isClosing, setClosingState] = useState<boolean>(false);

  const [isShowProfileInfo, toggleProfileInfoState] = useState<boolean>(false);

  const handleOpenDepositModal = () => {
    setModalInfo(ModalTypes.MoneyOperations);
  };

  const toggleProfileInfo = async (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.preventDefault();

    if (!isMobile) {
      navigate(ERouteNames.ProfileAccount);

      return;
    }

    const newState = !isShowProfileInfo;

    if (!newState) {
      setClosingState(true);

      await sleep(300);

      setClosingState(false);
    }
    toggleProfileInfoState(!isShowProfileInfo);
  };

  const toggleBalanceState = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.preventDefault();
    setPopUpBalanceState(!isPopUpBalance);
  };

  const handleClose = async (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.preventDefault();
    setClosingState(true);

    await sleep(300);

    setClosingState(false);
    setPopUpBalanceState(!isPopUpBalance);
  };

  return (
    <>
      <div className={styles.row}>
        <div className={cln(styles.balanceWrapper, styles.row)}>
          {!isCasinoGameHeader && (
            <div className={styles.balanceText} onClick={toggleBalanceState}>
              <ArrowSvg classes={styles.arrow} />

              {balanceInfo?.mainBalance}

              {currencyName}
            </div>
          )}

          <DepositButton
            isMobile={isMobile}
            appType={APP_TYPE}
            handleClick={handleOpenDepositModal}
            text={getText('walletPopUp.tabs.deposit')}
          />

          {isPopUpBalance && (
            <div className={styles.wrapperBalanceList}>
              <BalanceList handleClose={handleClose} />
            </div>
          )}
        </div>

        {!isMobile && <DesktopProfile />}
      </div>

      {isMobile &&
        (isPopUpBalance || isShowProfileInfo) &&
        ReactDOM.createPortal(
          <div
            className={cln(styles.portalBalanceWrapper, {
              [animation.animationFadeIn]: !isClosing,
              [animation.animationFadeOut]: isClosing,
            })}
          />,
          document.body
        )}
    </>
  );
};

export default ProfileInfo;
