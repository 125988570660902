import { useBetSlipSpecialOdd } from 'decryption_protocol_hooks';
import React from 'react';
import { isAvailableTransportSelector } from 'stores/connection/selectors';
import { useZustandConnectionStore } from 'stores/connection/store';

const SystemOdd = () => {
  const isConnectedApp = useZustandConnectionStore(
    isAvailableTransportSelector
  );
  const odd = useBetSlipSpecialOdd({ isConnectedApp, systemOdd: true }) as {
    system: string;
  };

  return <div>{odd.system}</div>;
};

export default SystemOdd;
