import cln from 'classnames';

import { useGetCasinoHomeCategories } from 'decryption_protocol_hooks';
import {
  ECasinoCategoryIds,
  ResponseHomePageInfo,
} from 'decryption_protocol/dist/lib/store/casino/entities/categories/types';

import ProviderRow from 'components/CasinoComponents/Providers/ProviderRow';
import CasinoCategoryRow from 'components/CasinoComponents/CasinoCategoryRow';

import { isAvailableTransportSelector } from 'stores/connection/selectors';
import { useZustandConnectionStore } from 'stores/connection/store';
import { useZustandDeviceStore } from 'stores/device/store';
import { isMobileDeviceSelector } from 'stores/device/selectors';

import styles from './HomeCategories.module.css';
import {
  CRAZY_TIME_ID,
  GATE_OF_OLYMPUS,
  IS_DEVELOPMENT_MODE,
} from 'constants/ui';
import VideoBanner from '../VideoBanner';
import JackpotWidget from 'components/ui/KitComponents/JackpotWidget';
import { useTranslate } from 'decryption_component-kit';

type Props = {
  classes?: string;
};

const HomeCategories = ({ classes }: Props) => {
  const isConnectedApp = useZustandConnectionStore(
    isAvailableTransportSelector
  );

  const isMobile = useZustandDeviceStore(isMobileDeviceSelector);

  const homePageInfo = useGetCasinoHomeCategories({
    isConnectedApp,
    isMobile,
  }) as ResponseHomePageInfo;

  const { name } = useTranslate('categories.original', '');

  return (
    <div className={cln(styles.wrapperHomeCategoriesRow, classes)}>
      {homePageInfo?.rows.map((info, inx: number) => {
        const videoComponentCrazy =
          inx === 1 && !window.ReactNativeWebView && isMobile ? (
            <VideoBanner
              // gameId={GATE_OF_OLYMPUS}
              gameId={CRAZY_TIME_ID}
              // source="gate-of-olympus.mp4"
              source="сrazy-time.mp4"
            />
          ) : null;

        const videoComponentGate =
          inx === 6 &&
          IS_DEVELOPMENT_MODE &&
          !window.ReactNativeWebView &&
          isMobile ? (
            <VideoBanner
              gameId={GATE_OF_OLYMPUS}
              source="gate-of-olympus.mp4"
            />
          ) : null;

        const jackpot =
          inx === 2 && IS_DEVELOPMENT_MODE && isMobile ? (
            <JackpotWidget classes={cln(styles.marginB, styles.marginR)} />
          ) : null;

        const video = videoComponentCrazy || videoComponentGate || null;

        const provider =
          inx === 2 ? <ProviderRow classes={styles.marginB} /> : null;

        if ('subCategoryId' in info) {
          return (
            <div key={info.subCategoryId}>
              {video}

              {provider}

              {jackpot}

              <CasinoCategoryRow
                categoryId={info.categoryId}
                name={info.name}
                subCategoryId={info.subCategoryId}
              />
            </div>
          );
        }

        return (
          <div key={info.id}>
            {video}

            {provider}

            {jackpot}

            <CasinoCategoryRow
              categoryId={info.id}
              name={info.id === ECasinoCategoryIds.Turbo ? name : info.name}
            />
          </div>
        );
      })}
    </div>
  );
};

export default HomeCategories;
