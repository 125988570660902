import { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  useGetProviderGames,
  useGetProviderInfoByIdOrName,
} from 'decryption_protocol_hooks';
import { IProvider } from 'decryption_protocol/dist/lib/store/casino/entities/providers/types';
import { CasinoGamesResponse } from 'decryption_protocol/dist/lib/store/casino/entities/games/types';

import { Text } from 'decryption_component-kit';
import CasinoSearchInput from 'components/CasinoComponents/CasinoSearchInput';
import CasinoGridGames from 'components/ui/CasinoGridGames';

import { CASINO_LIMIT_GAMES } from 'constants/transport';
import { useZustandCasinoSearch } from 'stores/casino/search';
import { getCasinoSearchValueSelector } from 'stores/casino/search/selectors';
import { useZustandDeviceStore } from 'stores/device/store';
import { isMobileDeviceSelector } from 'stores/device/selectors';
import { useZustandConnectionStore } from 'stores/connection/store';
import { isAvailableTransportSelector } from 'stores/connection/selectors';

import styles from './CasinoProviderGames.module.css';

const CasinoProviderGames = () => {
  const { id = 0 } = useParams();

  const [page, setPage] = useState<number>(1);

  const isConnectedApp = useZustandConnectionStore(
    isAvailableTransportSelector
  );
  const provider = useGetProviderInfoByIdOrName({
    providerId: +id,
    isConnectedApp,
  }) as IProvider;

  const search = useZustandCasinoSearch(getCasinoSearchValueSelector);

  const isMobile = useZustandDeviceStore(isMobileDeviceSelector);

  const { games = [], isLastPage = true } = useGetProviderGames({
    id,
    page,
    search,
    limit: CASINO_LIMIT_GAMES,
    isConnectedApp,
    isMobile,
  }) as CasinoGamesResponse;

  const handleSetPage = () => {
    setPage(page + 1);
  };

  return (
    <div className={styles.casinoProviderGamesWrapper}>
      <div className={styles.title}>
        <Text idT={provider.name || 'loading'} />
      </div>

      <div className={styles.casinoWrapperSearchInput}>
        <CasinoSearchInput classes={styles.filterWidth} />
      </div>

      <div className={styles.providerGames}>
        <CasinoGridGames
          games={games}
          isLastPage={isLastPage}
          handleClickShowMore={handleSetPage}
        />
      </div>
    </div>
  );
};

export default CasinoProviderGames;
