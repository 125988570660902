import { useState } from 'react';
import { useDailyTasks, useDailyActiveTask } from 'decryption_protocol_hooks';
import { DailyInfoType } from 'decryption_protocol/dist/lib/store/user/entities/daily/types';
import { ModalLayout } from 'decryption_component-kit';
import { useZustandModalStore } from 'stores/modal/store';
import { setModalInfoAction } from 'stores/modal/selectors';
import { ModalTypes } from 'stores/modal/types';

import styles from './KGamesDaily.module.css';
import { useZustandConnectionStore } from 'stores/connection/store';
import { isAvailableTransportSelector } from 'stores/connection/selectors';
import ComponentKitBaseButton from 'components/ui/KitComponents/Buttons/BaseButton';
import DailyTask from './components/DailyTask';

const KGamesDaily = () => {
  const setModal = useZustandModalStore(setModalInfoAction);

  const isConnectedApp = useZustandConnectionStore(
    isAvailableTransportSelector
  );

  const dailyTasks = useDailyTasks({ isConnectedApp }) as DailyInfoType[];

  const activeDailyTask = useDailyActiveTask({
    isConnectedApp,
  }) as null | DailyInfoType;

  const onCloseModal = () => {
    setModal(ModalTypes.Unknown);
  };

  return (
    <ModalLayout
      popUpHeaderProps={{
        title: 'T.Daily tasks',
      }}
      buttonClose={onCloseModal}
    >
      <>
        {dailyTasks?.map((d) => (
          <DailyTask
            key={d.id}
            {...d}
            isActive={activeDailyTask?.id === d.id}
          />
        ))}

        <ComponentKitBaseButton
          text="Base Button"
          handleClick={console.info}
          size="--button-full"
          buttonHeight="--button-height-full"
          centreText
          color="primary"
        />
      </>
    </ModalLayout>
  );
};

export default KGamesDaily;
