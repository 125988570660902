import { useNavigate } from 'react-router';
import { useEffect, useMemo, useState } from 'react';
import cln from 'classnames';
import { useUserMinOdd } from 'decryption_protocol_hooks';
import { ECasinoCategoryIds } from 'decryption_protocol/dist/lib/store/casino/entities/categories/types';

import { InfoSvg, Text, useTranslate } from 'decryption_component-kit';
import Presets from 'components/ui/Buttons/Presets';
import ComponentKitBaseButton from 'components/ui/KitComponents/Buttons/BaseButton';
import BaseInputUi from '../Base/BaseInputUi';

import { DEFAULT_INPUT_VALUE } from '../Base/constant';

import {
  composeValidations,
  extractNumbersFromString,
  isMinMax,
  isRequired,
} from 'utils/validation';

import { useZustandUserStore } from 'stores/user/personal/store';
import { getUserCurrencyNameSelector } from 'stores/user/personal/selectors';
import { useZustandModalStore } from 'stores/modal/store';
import { useZustandBalanceStore } from 'stores/user/balance/store';
import {
  getCommissionsSelector,
  getMainBalanceValueSelector,
  getRolledWithTaxSelector,
} from 'stores/user/balance/selectors';
import { closeAndOpenModalAction } from 'stores/modal/selectors';
import { useZustandConnectionStore } from 'stores/connection/store';
import { isAvailableTransportSelector } from 'stores/connection/selectors';

import { ModalTypes } from 'stores/modal/types';
import { DefaultInputType, InputBaseProps } from '../Base/types';
import { ERouteNames } from 'Routes/types';

import styles from './AmountInput.module.css';

type Props = InputBaseProps & {
  min: number;
  max: number;
  wrapperClasses?: string;
  isPresets?: boolean;
  isWithdraw?: boolean;
};

const AmountInput = (props: Props) => {
  const {
    isWithdraw = false,
    min,
    max,
    onChange,
    wrapperClasses,
    isPresets = true,
    defaultValue,
    disabled,
    placeholder,
  } = props;

  const isConnectedApp = useZustandConnectionStore(
    isAvailableTransportSelector
  );

  const minDepOdd = useUserMinOdd({ isConnectedApp });

  const validMin = useMemo(
    () => (isWithdraw ? min : min * minDepOdd),
    [isWithdraw, minDepOdd, min]
  );

  const [applyedValidMin, setValidMin] = useState(0);

  const withdrawAmount =
    useZustandBalanceStore(getMainBalanceValueSelector) || 0;

  const commission = useZustandBalanceStore(getCommissionsSelector) || 0;

  const currencyName = useZustandUserStore(getUserCurrencyNameSelector);

  const rolledWithTax = useZustandBalanceStore(getRolledWithTaxSelector);

  const navigate = useNavigate();

  const setModal = useZustandModalStore(closeAndOpenModalAction);

  const [amount, setAmount] = useState<DefaultInputType>({
    ...DEFAULT_INPUT_VALUE,
    value: defaultValue || '',
  });

  const [withdrawWithCommission, applyWithdrawCommissions] =
    useState<boolean>(false);

  const { name } = useTranslate(placeholder, '');

  const isCommissionInfoBox =
    isWithdraw && commission && amount.value && !withdrawWithCommission;

  const jumpTo = () => {
    navigate(
      ERouteNames.CasinoCategories.replace(
        ':categoryId',
        `${ECasinoCategoryIds.Popular}`
      )
        .replace(':subcategoryId', 'all')
        .replaceAll(':page', '1')
        .replaceAll(':scroll', '0')
    );
    setModal(ModalTypes.Unknown);
  };

  const setApplyWithdrawCommissions = () => {
    applyWithdrawCommissions(true);

    const errorText = composeValidations([
      isRequired({ value: amount.value, errorText: 'errors.required' }),
      isMinMax({
        value: amount.value,
        errorTexts: ['errors.min', 'errors.max'],
        min: validMin / 0.8,
        max,
      }),
    ]);

    setAmount({ value: amount.value, errorText });
  };

  const inputPlaceholder = useMemo(() => {
    const minSum = withdrawWithCommission ? validMin / 0.8 : validMin;

    const replacedText = '([MIN] - [MAX])'
      .replace('[MIN]', `${minSum} ${currencyName}`)
      .replace('[MAX]', `${max} ${currencyName}`);

    return `${name} ${replacedText}`;
  }, [validMin, max, withdrawWithCommission]);

  const handlePreset = (value: string) => {
    if (disabled) {
      return;
    }
    setAmount({ value, errorText: '' });
  };

  const handleAmountChange = ({
    target,
  }: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = target;

    const newV = extractNumbersFromString(value);

    const minSum = commission && isWithdraw ? validMin / 0.8 : validMin;

    let maxWithdraw = max;

    if (isWithdraw && withdrawAmount < max) {
      maxWithdraw = withdrawAmount;
    }

    const errorText = composeValidations([
      isRequired({ value: newV, errorText: 'errors.required' }),
      isMinMax({
        value: newV,
        errorTexts: ['errors.min', 'errors.max'],
        min:
          //  isWithdraw ?
          minSum,
        // : min,
        max: maxWithdraw,
      }),
    ]);

    setAmount({ value: newV, errorText });
  };

  useEffect(() => {
    const isCommissionInfoBox =
      isWithdraw && commission && amount.value && !withdrawWithCommission;

    if (amount.value && !amount.errorText && !isCommissionInfoBox) {
      onChange(amount.value);

      return;
    }

    onChange('');
  }, [amount, onChange, isWithdraw, commission, withdrawWithCommission]);

  useEffect(() => {
    if (isWithdraw) {
      return;
    }

    if (applyedValidMin === validMin) {
      return;
    }

    const newV = validMin * 1.5;

    setValidMin(validMin);

    if (+amount.value >= validMin) {
      return;
    }

    setAmount({ value: `${newV}`, errorText: '' });
  }, [validMin, amount, withdrawAmount, applyedValidMin]);

  return (
    <div className={cln(styles.wrapperAmountInput, wrapperClasses)}>
      <BaseInputUi
        {...props}
        placeholder={inputPlaceholder}
        handleChange={handleAmountChange}
        value={amount.value}
        errorText={amount.errorText}
        classes={cln(props.classes, {
          [wrapperClasses || '']: isCommissionInfoBox,
        })}
      >
        <>
          <div className={styles.currencyNameColor}>{currencyName}</div>

          {isWithdraw && !!commission && !!amount.value && (
            <InfoSvg classes={styles.infoCommissionWrapper} />
          )}
        </>
      </BaseInputUi>

      {isPresets && (
        <Presets
          min={validMin * 1.5}
          max={validMin * 2.5}
          handleSetAmount={handlePreset}
          activeAmount={amount.value}
        />
      )}

      {isWithdraw &&
        !!commission &&
        !!amount.value &&
        !withdrawWithCommission && (
          <div className={styles.commissionInfoWrapper}>
            <div className={styles.commissionInfoBox}>
              <InfoSvg classes={styles.infoIcon} />

              <div className={styles.textBlock}>
                <div className={styles.rules}>
                  <Text
                    idT="walletPopUp.forms.commissionsBody"
                    specValue={`${rolledWithTax} ${currencyName}`}
                  />
                </div>
                <div className={styles.additionalInfo}>
                  <Text
                    idT="walletPopUp.forms.amountReceivable"
                    specValue={` ${Math.floor(
                      +amount.value * 0.8
                    )} ${currencyName}`}
                  />
                </div>
              </div>
            </div>

            <div className={styles.wrapperButtons}>
              <ComponentKitBaseButton
                text="walletPopUp.forms.goGame"
                size="--button-full"
                buttonHeight="--button-height-xl"
                color="primary"
                // isResized
                handleClick={jumpTo}
                classes={styles.goGameBtn}
                centreText
              />

              <ComponentKitBaseButton
                text="walletPopUp.forms.withWithdraw"
                size="--button-full"
                buttonHeight="--button-height-xl"
                // isResized
                color="dark"
                handleClick={setApplyWithdrawCommissions}
                classes={styles.goGameBtn}
                centreText
              />
            </div>
          </div>
        )}
    </div>
  );
};

export default AmountInput;
