import cln from 'classnames';
import { useGetCasinoGameById } from 'decryption_protocol_hooks';

import CasinoGame from 'components/CasinoComponents/CasinoGame';

import { useZustandDeviceStore } from 'stores/device/store';
import { isMobileDeviceSelector } from 'stores/device/selectors';
import { useZustandConnectionStore } from 'stores/connection/store';
import { isAvailableTransportSelector } from 'stores/connection/selectors';

import { AdaptedGame } from 'decryption_protocol/dist/lib/store/casino/entities/games/types';

import styles from './ProviderCasinoGame.module.css';

type Props = {
  gameId: number;
};

const ProviderCasinoGame = ({ gameId }: Props) => {
  const isMobile = useZustandDeviceStore(isMobileDeviceSelector);

  const isConnectedApp = useZustandConnectionStore(
    isAvailableTransportSelector
  );

  const game = useGetCasinoGameById({
    gameId,
    isMobile,
    isConnectedApp,
  }) as AdaptedGame | null;

  if (!game) {
    return null; //<SkeletLoader classes={styles.loaderWrapper} />;
  }

  return (
    <CasinoGame
      {...game}
      classes={cln(styles.gameWrapper, styles.heightForClass)}
    />
  );
};

export default ProviderCasinoGame;
