import { useBetSlipSpecialOdd } from 'decryption_protocol_hooks';
import React from 'react';
import { isAvailableTransportSelector } from 'stores/connection/selectors';
import { useZustandConnectionStore } from 'stores/connection/store';

const ExpressOdd = () => {
  const isConnectedApp = useZustandConnectionStore(
    isAvailableTransportSelector
  );

  const odd = useBetSlipSpecialOdd({ isConnectedApp, expressOdd: true }) as {
    express: string;
  };

  return <div>{odd.express}</div>;
};

export default ExpressOdd;
