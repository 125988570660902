import React, { useEffect, useState } from 'react';
import { getLicenseInfosSelector } from 'stores/configuration/selectors';
import { useZustandWebConfiguration } from 'stores/configuration/store';

import styles from './AnguanLicense.module.css';

const AnguanLicense = () => {
  const [loaded, setLoaded] = useState(false);

  const licenseInfo = useZustandWebConfiguration(getLicenseInfosSelector);

  const isCurrentDomainLicensed = licenseInfo?.domains?.includes(
    window.location.host
  );

  const handleRedirect = () => {
    if (!licenseInfo?.domains.includes(window.location.host)) {
      return;
    }
    const a = document.createElement('a');

    a.target = '_blank';

    a.href = `https://verification.anjouangaming.org/validate?domain=${window.location.host}&seal_id=a70359d347c190288b91992805d9ddfe8e1a37a1f3fc4fcce5d40a4a9f07742ad4cbe76ec95e89770efb970526f2dda9&stamp=f12912c72dd7bd97c1d340b6ed463d8e`;

    a.click();
  };

  useEffect(() => {
    if (!licenseInfo || !isCurrentDomainLicensed) {
      return;
    }
    const script = document.createElement('script');

    script.src = licenseInfo?.validatorSite;

    script.async = true;
    script.addEventListener('load', () => setLoaded(true));

    document.body.appendChild(script);
  }, []);

  useEffect(() => {
    if (!loaded) {
      return;
    }

    const key = `anj_${licenseInfo?.validatorLink}`.replaceAll('-', '_');

    // @ts-ignore
    window[key]?.init();
  }, [loaded]);

  return (
    <div>
      {isCurrentDomainLicensed ? (
        <div
          id={`anj-${licenseInfo?.validatorLink}`}
          data-anj-seal-id={licenseInfo?.validatorLink}
          data-anj-image-size="32"
          data-anj-image-type="basic-small"
        />
      ) : (
        <div onClick={handleRedirect}>
          <img
            src="/icons-elon/footer/anguan.png"
            className={styles.licenseImg}
          />
        </div>
      )}
    </div>
  );
};

export default AnguanLicense;
