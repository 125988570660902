import cln from 'classnames';

import { IProvider } from 'decryption_protocol/dist/lib/store/casino/entities/providers/types';

import ProviderBox from '../../../ui/Buttons/ProviderButtons';
import CasinoHomeRowHeader from './components/ProviderRowHeader';

import { useGetProviders } from 'decryption_protocol_hooks';

import { useZustandConnectionStore } from 'stores/connection/store';
import { isAvailableTransportSelector } from 'stores/connection/selectors';
import { useZustandDeviceStore } from 'stores/device/store';
import { isMobileDeviceSelector } from 'stores/device/selectors';

import styles from './ProviderRow.module.css';

type Props = {
  classes?: string;
};

const ProviderRow = ({ classes }: Props) => {
  const isMobile = useZustandDeviceStore(isMobileDeviceSelector);

  const isConnectedApp = useZustandConnectionStore(
    isAvailableTransportSelector
  );

  const providers = useGetProviders({
    isMobile,
    isConnectedApp,
  }) as IProvider[];

  return (
    <div className={cln(styles.wrapperProviderRows, classes)}>
      <CasinoHomeRowHeader categoryName="drawers.casino.providerBtn" />

      <div className={styles.providerRowsWrapper}>
        {providers.map((p) => (
          <ProviderBox name={p.name} id={p.id} key={p.id} />
        ))}
      </div>
    </div>
  );
};

export default ProviderRow;
