import { useEffect, useState } from 'react';

import Tabs from './components/Tabs';
import BonusOnDeposit from 'components/ui/Bonuses/BonusOnDeposit';
import FreeSpin from 'components/ui/Bonuses/FreeSpin';
import ComponentKitBaseButton from 'components/ui/KitComponents/Buttons/BaseButton';
import HOCRequestLoader from 'components/HOCs/HOCRequestLoader';

import useBonusPacks, { AdaptedBonusInfo, PackName } from 'hooks/useBonusPack';

import { ERequestIds } from 'decryption_protocol/dist/lib/binary/types';
import { useTransportControllers } from 'decryption_protocol_hooks';

import styles from './BonusPack.module.css';

const BonusPackInfo = () => {
  const [activePackName, setActivePackName] = useState<PackName | null>(null);

  const [bonuses, setPackBonuses] = useState<AdaptedBonusInfo[]>([]);

  const packs = useBonusPacks();

  const { sendCommand } = useTransportControllers();

  const handleSetInx = (packName: PackName) => () => {
    setActivePackName(packName);
  };

  const handleApplyBonusPack = () => {
    if (!activePackName || !bonuses.length) {
      return;
    }

    const packUid = bonuses[0].packId;

    // ERequestIds.ApplyBonusPack
    sendCommand(
      '24x',
      {
        packUid,
        requestId: ERequestIds.ApplyBonusPack,
      },
      true
    );
  };

  useEffect(() => {
    if (activePackName || !packs?.length) {
      return;
    }
    setActivePackName(packs[0].packName);
  }, [packs, activePackName]);

  useEffect(() => {
    if (!activePackName || !packs?.length) {
      return;
    }

    const bonuses =
      packs?.find((p) => p.packName === activePackName)?.bonuses || [];

    setPackBonuses(bonuses);
  }, [packs, activePackName]);

  if (!packs) {
    return <div>Loading packs ...</div>;
  }

  return (
    <div className={styles.wrapperBonusPack}>
      {activePackName && (
        <Tabs
          activePackName={activePackName}
          changeInx={handleSetInx}
          packs={packs}
        />
      )}

      <div className={styles.desktopWrapper}>
        {bonuses.map((b) => {
          if (b.type === 'deposit') {
            return (
              <BonusOnDeposit
                isPack
                maxBet={b.bonusInfo.max_bet}
                minBet={b.bonusInfo.min_bet}
                maxBonus={b.bonusInfo.max_bonus}
                key={`${b.bonusOffer}_${b.packId}`}
                operationId={-1}
                wager={b.wager}
                promotion={b.bonusOffer}
                timeStamp={0}
                classes={styles.bonusWrapper}
              />
            );
          }

          return (
            <FreeSpin
              isPack
              key={`${b.bonusOffer}_${b.packId}`}
              operationId={-1}
              amount={-1}
              spins={b.bonusOffer}
              gameId={b.gameId}
              minBet={b.bonusInfo.min_bet}
              maxBet={b.bonusInfo.max_bet}
              maxBonus={b.bonusInfo.max_bonus}
              endActivationTime={0}
              wager={b.wager}
              classes={styles.bonusWrapper}
            />
          );
        })}
      </div>

      {activePackName && (
        <HOCRequestLoader requestId={ERequestIds.ApplyBonusPack} command="24x">
          <ComponentKitBaseButton
            text="bonusCabinet.packs.applyPack"
            handleClick={handleApplyBonusPack}
            size="--button-full"
            buttonHeight="--button-height-l"
            color="primary"
            centreText
          />
        </HOCRequestLoader>
      )}
    </div>
  );
};

export default BonusPackInfo;
