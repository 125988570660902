import React, { useRef, useState } from 'react';
import cln from 'classnames';

import useInView from 'hooks/useInView';

import TicketTitle from '../Templates/TicketTitle';
import CashOutButton from 'components/ui/Buttons/CashOutButton';
import StakeInfo from '../Templates/StakeInfo';
import { Ordinar } from 'decryption_protocol/dist/lib/store/betting/entities/tickets/types';
import TicketEventInfo from '../Templates/TicketEventInfo';

import ticketStyles from '../../Tickets.module.css';
import MatchStatus from '../Templates/MatchStatus';

type Props = {
  cashoutRecords: any[]; // TODO
  timestamp: number;
  ticketId: number;
  winAmount: number;
  acceptedOdd: number;
  stake: number;
  ordinars: Ordinar[];
};

const ExpressTicket = (props: Props) => {
  const {
    cashoutRecords,
    timestamp,
    ticketId,
    stake,
    winAmount,
    ordinars,
    acceptedOdd,
  } = props;

  const wrapperContainer = useRef<HTMLDivElement | null>(null);

  const [isVisible, setVisibleState] = useState<boolean>(true);

  const isInView = useInView(wrapperContainer, {
    rootMargin: '150px',
  });

  return (
    <div
      className={cln(ticketStyles.ticketWrapper, {
        [ticketStyles.isHiddenContend]: !isVisible,
        [ticketStyles.disabledPaddingBtn]: cashoutRecords.length,
      })}
      style={{
        minHeight: `calc(var(--ticket-stake-info) + var(--ticket-header-height) + var(--ticket-ordinar-height) * ${ordinars.length})`,
      }}
      ref={wrapperContainer}
    >
      <TicketTitle
        timestamp={timestamp}
        ticketId={ticketId}
        classes={ticketStyles.horizontalPadding}
        toggleState={setVisibleState}
        isVisible={isVisible}
        ticketTitle="express"
      />

      {isVisible && (
        // {isVisible && isInView && (
        <>
          {ordinars.map((ordinar) => (
            <div className={ticketStyles.ordinarInfo} key={ordinar.id}>
              <TicketEventInfo
                eventHomeName={ordinar.eventHomeName}
                eventAwayName={ordinar.eventAwayName}
                marketName={ordinar.marketName}
                outcomeName={ordinar.outcomeName}
              />

              <div className={ticketStyles.rightColumn}>
                <div className={ticketStyles.acceptedOdd}>
                  {ordinar.acceptedOdd}
                </div>

                <MatchStatus
                  status={ordinar.status}
                  isWin={Boolean(ordinar.settlementOdd)}
                />
              </div>
            </div>
          ))}

          <StakeInfo
            stake={stake}
            acceptedOdd={acceptedOdd}
            winAmount={winAmount}
            classes={ticketStyles.marginB}
          />

          <CashOutButton
            ticketId={ticketId}
            classes={cln(ticketStyles.horizontalPadding)}
          />
        </>
      )}
    </div>
  );
};

export default ExpressTicket;
