/* eslint-disable no-console */
import { useEffect, useMemo, useState } from 'react';
import {
  useTransportControllers,
  useAdvancePayments,
} from 'decryption_protocol_hooks';
import cln from 'classnames';
import { COINPAY_CRYPTO } from 'decryption_protocol/dist/lib/store/user/constants';
import {
  PaymentTypes,
  Payment,
} from 'decryption_protocol/dist/lib/store/user/types';

import PaymentMessage from './components/Message';
import WithdrawSkeleton from './components/Forms/Withdraw/WithdrawSkeleton';
import DepositSkeleton from './components/Forms/Deposit/DepositSkelet';
import HOCRequestLoader from 'components/HOCs/HOCRequestLoader';
import CircularLoader from 'components/ui/Loaders/Circular';
import PopUpHeader from 'components/ui/mobile/PopUpHeader';
import ComponentKitBaseButton from 'components/ui/KitComponents/Buttons/BaseButton';

import PaymentFrame from './components/PaymentFrame';
import Tabs from './components/Tabs';
import Methods from './components/Methods';

import { useZustandModalStore } from 'stores/modal/store';
import { closeAndOpenModalAction } from 'stores/modal/selectors';
import { ModalTypes } from 'stores/modal/types';
import { useZustandFormsStore } from 'stores/money/forms/store';
import { useZustandPaymentsStore } from 'stores/money/payments/store';
import {
  getActivePaymentChannelSelector,
  getActivePaymentInfoSelector,
  getActivePaymentsTabSelector,
  getCurrentPaymentStepSelector,
  setActivePaymentChannelAction,
  setActivePaymentInfoAction,
  setActivePaymentsTabAction,
  setPaymentsCurrentStepAction,
} from 'stores/money/payments/selectors';
import { Steps } from 'stores/money/payments/types';
import {
  getFormsLoaderInfoSelector,
  setBdtTwpStepInfoAction,
  setFormsButtonLoaderStateAction,
  setFormsInfoAction,
} from 'stores/money/forms/selectors';
import { useZustandConnectionStore } from 'stores/connection/store';
import { isAvailableTransportSelector } from 'stores/connection/selectors';

import { prepareDataForGettingBillingAddress } from 'utils/transport/depositAdapter';
import { getDepositInfo } from './utils/deposit';
import { getWithdrawInfo } from './utils/withdraw';
import {
  IS_DEVELOPMENT_MODE,
  IS_DISABLED_SPEC_PAYMENT_LOGIC,
} from 'constants/ui';

import styles from './MoneyOperations.module.css';
import animation from 'styles/animation.module.css';

const MoneyOperations = () => {
  const isConnectedApp = useZustandConnectionStore(
    isAvailableTransportSelector
  );

  const listInfo = useAdvancePayments({
    isConnectedApp,
    isMock: IS_DEVELOPMENT_MODE,
    isBaseLogic: IS_DEVELOPMENT_MODE || IS_DISABLED_SPEC_PAYMENT_LOGIC,
    filePath: 'Scripts/payments_elon.json',
  }) as PaymentTypes;

  const activeTab = useZustandPaymentsStore(getActivePaymentsTabSelector);

  const { sendCommand } = useTransportControllers();

  const setActiveTab = useZustandPaymentsStore(setActivePaymentsTabAction);

  const step = useZustandPaymentsStore(getCurrentPaymentStepSelector);

  const setStep = useZustandPaymentsStore(setPaymentsCurrentStepAction);

  const setFormInfo = useZustandFormsStore(setFormsInfoAction);

  const setPaymentInfo = useZustandPaymentsStore(setActivePaymentInfoAction);

  const setBdtTwoStep = useZustandFormsStore(setBdtTwpStepInfoAction);

  const [limit, setLimit] = useState<{ min: number; max: number } | null>(null);

  const activePayment = getActivePaymentInfoSelector(
    useZustandPaymentsStore.getState()
  );

  const setActiveMethod = useZustandPaymentsStore(
    setActivePaymentChannelAction
  );

  const activeChannelId = useZustandPaymentsStore(
    getActivePaymentChannelSelector
  );

  const loaderInfo = useZustandFormsStore(getFormsLoaderInfoSelector);

  const isShowButton = useMemo(() => {
    if (step === Steps.MessageScreen || step === Steps.ChooseMethod) {
      return false;
    }

    if (activeChannelId && COINPAY_CRYPTO.includes(activeChannelId)) {
      return activeTab === 'withdraw';
    }

    return true;
  }, [step, activeChannelId, activeTab]);

  const setDepositLoaderInfo = useZustandFormsStore(
    setFormsButtonLoaderStateAction
  );

  const setModal = useZustandModalStore(closeAndOpenModalAction);

  const isDisabled = useMemo(() => {
    if (step === Steps.MessageScreen) {
      return false;
    }

    if (step === Steps.ChooseMethod) {
      return !activeChannelId;
    }

    if (Steps.FilledForm === step || Steps.BdtStepTwo === step) {
      return false;
    }

    return true;
  }, [step, activeChannelId]);

  const handleSelectMethod = (
    payment: Payment //PaymentChannelType | null
  ) => {
    setActiveMethod(payment.id);
    handleNextStep();
    setPaymentInfo(payment);
  };

  const getHeaderTitle = () => {
    if (step === Steps.ChooseMethod || !activePayment) {
      return 'walletPopUp.title';
    }

    return activePayment.channelName || 'walletPopUp.title';
  };

  const getButtonText = () => {
    if (Steps.FilledForm === step) {
      return 'walletPopUp.forms.submit';
    }

    if (Steps.MessageScreen === step) {
      return 'walletPopUp.forms.closeMessageInfo';
    }

    return 'walletPopUp.forms.next';
  };

  const getButtonClickFunction = () => {
    if (Steps.FilledForm === step) {
      return submitForm;
    }

    if (Steps.MessageScreen === step) {
      return handlePrevStep;
    }

    return handleNextStep;
  };

  const onClose = () => {
    setModal(ModalTypes.Unknown);

    setFormInfo(null);
    setBdtTwoStep(null);
    setPaymentInfo(null);
    setStep(Steps.ChooseMethod);
  };

  const handleNextStep = () => {
    if (Steps.ChooseMethod === step) {
      return setStep(Steps.Amount);
    }

    const data = prepareDataForGettingBillingAddress();

    console.log('🚀 ~ handleNextStep ~ data:', data);

    if (!data) {
      console.error('BDT BILLING ADDRESS DATA ERROR');

      return;
    }

    setDepositLoaderInfo({
      command: '13x',
      requestId: data.requestId,
    });

    sendCommand('13x', data, true);
  };

  const handlePrevStep = () => {
    const prevState = Steps.ChooseMethod;

    setStep(prevState);
    setFormInfo(null);
    setBdtTwoStep(null);
  };

  const submitForm = () => {
    if (!activeChannelId) {
      return;
    }
    const info =
      activeTab === 'deposit'
        ? getDepositInfo(activeChannelId)
        : getWithdrawInfo(activeChannelId);

    console.group('WALLET SEND INFO');
    console.info(activeTab);
    console.log('🚀 ~ send info:', info);
    console.groupEnd();

    if (!info) {
      return;
    }
    const { key, data } = info;

    sendCommand(key, data, true);

    // Need to add loader for button by form
    setDepositLoaderInfo({
      command: key,
      requestId: data.requestId,
    });
  };

  useEffect(() => {
    const data = activeTab !== 'deposit' ? listInfo.withdraw : listInfo.deposit;

    const limit = data.find((d: any) => d.id === activeChannelId)?.limit;

    if (!limit) {
      setLimit({ min: 0, max: 0 });

      return;
    }

    setLimit({
      min: limit.min,
      max: limit.max,
    });
  }, [activeChannelId, listInfo, activeTab]);

  useEffect(
    () => () => {
      setStep(Steps.ChooseMethod);
      setActiveTab('deposit');
      setPaymentInfo(null);
    },
    []
  );

  return (
    <div className={styles.wrapperMoneyOperations}>
      <div className={styles.walletForms}>
        <PopUpHeader
          title={getHeaderTitle()}
          backButton={step !== Steps.ChooseMethod ? handlePrevStep : undefined}
          closeButton={onClose}
          classes={styles.headerWrapper}
        />

        <div className={styles.content}>
          <div
            className={cln(styles.formWrapper, animation.animationFadeIn)}
            style={{
              height:
                step === Steps.MessageScreen || step === Steps.ChooseMethod
                  ? '100%'
                  : 'initial',
              overflow: step === Steps.MessageScreen ? 'hidden' : 'auto',
            }}
          >
            {step === Steps.ChooseMethod && (
              <>
                <Tabs activeTab={activeTab} setActiveTab={setActiveTab} />

                <Methods
                  activeTab={activeTab}
                  setActiveMethod={handleSelectMethod}
                  listInfo={listInfo}
                  activeChannelId={activeChannelId}
                />
              </>
            )}

            {step !== Steps.ChooseMethod && activeTab === 'deposit' && (
              <DepositSkeleton
                activeChannelId={activeChannelId}
                limit={limit}
              />
            )}

            {step !== Steps.ChooseMethod &&
              activeTab === 'withdraw' &&
              limit &&
              activeChannelId && (
                <WithdrawSkeleton
                  activeChannelId={activeChannelId}
                  limit={limit}
                />
              )}

            {step === Steps.MessageScreen && <PaymentMessage />}

            {step === Steps.RedirectInFrame && <PaymentFrame />}
          </div>

          {isShowButton && (
            <HOCRequestLoader
              {...loaderInfo}
              customLoader={<CircularLoader classes={styles.loader} />}
            >
              <ComponentKitBaseButton
                color="primary"
                size="--button-full"
                buttonHeight="--button-height-l"
                text={getButtonText()}
                handleClick={getButtonClickFunction()}
                centreText
                disabled={isDisabled}
                classes={styles.submitButton}
              />
            </HOCRequestLoader>
          )}
        </div>
      </div>
    </div>
  );
};

export default MoneyOperations;
